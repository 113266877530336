import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// eslint-disable-next-line no-shadow
export enum ModalName {
  provider = "showProviderModal",
}

interface IModalState {
  showProviderModal: boolean;
}

const initialState: IModalState = {
  showProviderModal: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, { payload }: PayloadAction<ModalName>) => {
      state[payload] = true;
    },
    closeModal: (state, { payload }: PayloadAction<ModalName>) => {
      state[payload] = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
