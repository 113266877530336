import { Avatar, Typography } from "antd";
import { FC } from "react";
import { createUseStyles } from "react-jss";
import { components } from "../../api/api";

const useStyles = createUseStyles({
  title: {
    textAlign: "center",
  },
  tokenInfo: {
    fontSize: 12,
    position: "absolute",
    left: 15,
    display: "flex",
    alignItems: "center",

    "& .ant-avatar": {
      marginRight: 4,
    },
  },
});

interface IProps {
  title: string;
  token?: components["schemas"]["Asset"];
}

const ActionTitle: FC<IProps> = ({ title, token }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      {token && (
        <span className={classes.tokenInfo}>
          <Avatar size={12} src={token.iconUrl} alt={token.name} />
          <Typography.Text>{token.name}</Typography.Text>
        </span>
      )}
      <Typography.Title level={5}>{title}</Typography.Title>
    </div>
  );
};

export default ActionTitle;
