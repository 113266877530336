import { ButtonType } from "antd/lib/button";
import axios from "axios";
import _ from "lodash";
import { ReactNode, useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import { hexlify } from "@ethersproject/bytes";
import { keccak256 as solidityKeccak256 } from "@ethersproject/solidity";
import { formatUnits, parseUnits } from "@ethersproject/units";

import { components, operations } from "../../api/api";
import { formatDecimal, formatPercentage } from "../../helpers/format";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { fetchAssets } from "../../redux/assetSlice";
import { useAppDispatch } from "../../redux/store";
import { fetchStrategies } from "../../redux/strategySlice";
import { Theme } from "../../theme";
import { ITokenInputChangeEvent } from "../TokenInput";
import ActionTitle from "./ActionTitle";
import ActionModal from "./common/ActionModal";
import ModalExtraInfoRow from "./common/ModalExtraInfoRow";
import ModalResult from "./common/ModalResult";
import ModalTokenInput from "./common/ModalTokenInput";

export interface FundExitModalProps {
  token: components["schemas"]["Asset"];
  strategy: components["schemas"]["Strategy"];
  onClose: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  apy: {
    display: "inline-block",
    padding: "4px 8px",
    margin: "-4px 0",
    background: theme.bgColorPrimary,
    color: theme.win,
    borderRadius: 8,
  },
}));

export default function FundModal({ token, strategy, onClose }: FundExitModalProps): JSX.Element {
  const classes = useStyles();
  const { address, signer, rpcUrl } = useWeb3Context();
  const [amount, setAmount] = useState("");
  const [errMsg, setErrMsg] = useState<string | undefined>("");
  const [resultMsg, setResultMsg] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const minFund = formatUnits(strategy.minFund || 0, token.decimal);

  const queryClient = useQueryClient();
  const fundMutation = useMutation<
    unknown,
    unknown,
    operations["strategyOperation"]["requestBody"]["content"]["application/x-www-form-urlencoded"],
    unknown
  >(data => axios.post(`${rpcUrl}/strategyOperation`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("investmentHistory");
      queryClient.invalidateQueries("strategies");
      setResultMsg(`Depositing funds to ${strategy.name} has been submitted`);
    },
    onError: e => {
      console.log(e);
      setErrMsg(_.get(e, "message"));
    },
  });

  const handleAction = async () => {
    let value;
    try {
      value = parseUnits(amount, token.decimal);
    } catch (e) {
      setErrMsg("Input is invalid");
      return;
    }

    if (value.lt(parseUnits(minFund, token.decimal))) {
      setErrMsg(`Please input a number larger than ${minFund}`);
      return;
    }

    if (value.gt(parseUnits(token.amount || "0", 0))) {
      setErrMsg("You don’t have enough L2 balance to deposit");
      return;
    }

    const timestamp = Date.now();
    const transitionHash = solidityKeccak256(
      ["uint8", "uint32", "uint256", "uint64"],
      [3 /* TRANSITION_TYPE_COMMIT */, strategy.id, value, timestamp],
    );
    if (!signer) {
      return;
    }
    setLoading(true);
    // NOTE: Force personal_sign to work around quirks in Coinbase Wallet and possibly others
    const signature = await signer.provider.send("personal_sign", [hexlify(transitionHash), address.toLowerCase()]);
    setShouldReload(true);
    const data = {
      type: 3,
      strategyId: strategy.id as number,
      amount: value.toString(), // asset amount
      timestamp,
      signature,
    };
    fundMutation.mutateAsync(data).finally(() => setLoading(false));
  };

  const handleModalClose = useCallback(() => {
    if (shouldReload) {
      dispatch(fetchStrategies({ address, tokenId: token.id }));
      dispatch(fetchAssets(address));
    }
    onClose();
  }, [address, dispatch, onClose, token.id, shouldReload]);

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
    setErrMsg(e.error);
  };

  let content: JSX.Element;
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extraInfo: ReactNode[];
  if (resultMsg) {
    content = (
      <ModalResult
        title={resultMsg}
        description={
          <span>
            It may take up to 6 hours to finalize your deposit to the strategy. You can check the progress in the
            history page.
          </span>
        }
      />
    );
    action = () => history.push("/history/investment");
    actionText = "Check History";
    buttonType = "link";
    extraInfo = [
      <ModalExtraInfoRow left="Strategy Name" right={strategy.name} />,
      <ModalExtraInfoRow left="Deposit Amount" right={`${amount} ${token.symbol}`} />,
      <ModalExtraInfoRow
        left="APY"
        right={<span className={classes.apy}>{formatPercentage(strategy.apy || 0, false)}</span>}
      />,
    ];
  } else {
    content = (
      <ModalTokenInput
        description="You can allocate your L2 balance to this strategy and receive earnings."
        amount={amount}
        maxAmount={formatDecimal(token.amount, token.decimal)}
        symbol={token.symbol}
        onChange={handleTokenInputChange}
        bottomDescription={`Minimal Deposit Amount: ${minFund} ${token.symbol}`}
      />
    );
    action = handleAction;
    actionText = "Deposit";
    buttonType = "primary";
    extraInfo = [
      <ModalExtraInfoRow
        left="APY"
        right={<span className={classes.apy}>{formatPercentage(strategy.apy || 0, false)}</span>}
      />,
      <ModalExtraInfoRow left="Strategy Name" right={strategy.name} />,
    ];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Deposit to Strategy" token={token} />}
      actionText={actionText}
      errMsg={errMsg}
      onCancel={handleModalClose}
      onAction={action}
      actionLoading={loading}
      buttonType={buttonType}
      extra={extraInfo}
    >
      {content}
    </ActionModal>
  );
}
