import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { components } from "../api/api";
import { getMainchainHistory } from "../api/history";

interface IMainchainHistorySlice {
  entries: components["schemas"]["MainchainHistoryEntry"][];
  hasMore: boolean;
  loading: boolean;
}

const initialState: IMainchainHistorySlice = {
  entries: [],
  hasMore: true,
  loading: false,
};

const mainchainHistorySlice = createSlice({
  name: "mainchainHistory",
  initialState,
  reducers: {
    save: (
      state,
      { payload }: PayloadAction<{ entries: components["schemas"]["MainchainHistoryEntry"][]; hasMore: boolean }>,
    ) => {
      state.entries = payload.entries.map(entry =>
        entry.assetSymbol === "WETH" ? { ...entry, assetSymbol: "ETH" } : entry,
      );
      state.hasMore = payload.hasMore;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const fetchMainchainHistory = createAsyncThunk(
  "mainchainHistory/fetchMainchainHistory",
  async ({ address, page }: { address: string; page: number }, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    const res = await getMainchainHistory(address, page);
    thunkApi.dispatch(save({ entries: res.entries, hasMore: res.more || false }));
    thunkApi.dispatch(setLoading(false));
  },
);

export const { save, setLoading } = mainchainHistorySlice.actions;

export default mainchainHistorySlice.reducer;
