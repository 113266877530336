import { ButtonType } from "antd/lib/button";
import axios from "axios";
import { BigNumber } from "ethers";
import _ from "lodash";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import { hexlify } from "@ethersproject/bytes";
import { keccak256 as solidityKeccak256 } from "@ethersproject/solidity";
import { formatUnits, parseUnits } from "@ethersproject/units";

import { operations } from "../../api/api";
import { formatDecimal } from "../../helpers/format";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { Theme } from "../../theme";
import { ITokenInputChangeEvent } from "../TokenInput";
import ActionTitle from "./ActionTitle";
import ActionModal from "./common/ActionModal";
import ModalExtraInfoRow from "./common/ModalExtraInfoRow";
import ModalResult from "./common/ModalResult";
import ModalTokenInput from "./common/ModalTokenInput";
import { DWModalProps } from "./DepositModal";

const useStyles = createUseStyles((theme: Theme) => ({
  heighlight: theme.highlightedText,
}));

export default function WithdrawModal({ token, onClose }: DWModalProps): JSX.Element {
  const classes = useStyles();

  const { signer, rpcUrl } = useWeb3Context();
  const [amount, setAmount] = useState("");
  const [errMsg, setErrMsg] = useState<string | undefined>("");
  const [resultMsg, setResultMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const minWithdraw = formatUnits(token.minWithdraw || 0, token.decimal);
  const queryClient = useQueryClient();
  const withdrawMutation = useMutation<
    unknown,
    unknown,
    operations["withdrawAsset"]["requestBody"]["content"]["application/x-www-form-urlencoded"],
    unknown
  >(data => axios.post(`${rpcUrl}/withdrawAsset`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("mainchainHistory");
      setResultMsg("Withdrawal has been submitted");
    },
    onError: e => {
      setErrMsg(_.get(e, "message"));
    },
  });

  const handleAction = async () => {
    let value: BigNumber;
    try {
      value = parseUnits(amount, token.decimal);
    } catch (e) {
      setErrMsg("Input is invalid");
      return;
    }

    if (value.lt(parseUnits(minWithdraw, token.decimal))) {
      setErrMsg(`Please input a number larger than ${minWithdraw}`);
      return;
    }

    if (value.gt(parseUnits(token.amount || "0", 0))) {
      setErrMsg("You don’t have enough L2 balance to withdraw");
      return;
    }

    if (!signer) {
      return;
    }

    const address = await signer.getAddress();
    const timestamp = Date.now();
    const transitionHash = solidityKeccak256(
      ["uint8", "address", "uint32", "uint256", "uint64"],
      [2 /* TRANSITION_TYPE_WITHDRAW */, address, token.id, value, timestamp],
    );
    setLoading(true);
    // NOTE: Force personal_sign to work around quirks in Coinbase Wallet and possibly others
    const signature = await signer.provider.send("personal_sign", [hexlify(transitionHash), address.toLowerCase()]);
    const data = {
      receiver: address,
      assetId: token.id,
      assetAmount: value.toString(),
      timestamp,
      signature,
    };
    withdrawMutation.mutateAsync(data).finally(() => setLoading(false));
  };

  const handleGoToHistory = () => {
    history.push("/history");
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
    setErrMsg(e.error);
  };

  let content = (
    <ModalTokenInput
      description="You can withdraw your available L2 balance to your L1 wallet."
      amount={amount}
      maxAmount={formatDecimal(token.amount, token.decimal)}
      symbol={token.symbol}
      onChange={handleTokenInputChange}
      bottomDescription={`Minimal Withdraw Amount: ${minWithdraw} ${token.symbol}`}
    />
  );
  let action: () => void = handleAction;
  let actionText = "Withdraw";
  let buttonType: ButtonType = "primary";

  if (resultMsg) {
    content = (
      <ModalResult
        title={resultMsg}
        description={
          <span>
            In order to receive the fund in your L1 wallet, you will need to{" "}
            <span className={classes.heighlight}>manually confirm</span> the withdrawal again 2-8 hours later in the
            history page.
          </span>
        }
      />
    );
    action = handleGoToHistory;
    actionText = "Check History";
    buttonType = "link";
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Withdraw to L1" token={token} />}
      actionText={actionText}
      errMsg={errMsg}
      onCancel={onClose}
      onAction={action}
      buttonType={buttonType}
      actionLoading={loading}
      extra={resultMsg ? [<ModalExtraInfoRow left="Withdraw Amount" right={`${amount} ${token.symbol}`} />] : []}
    >
      {content}
    </ActionModal>
  );
}
