const theme = {
  successColor: "#68DE31",
  win: "#09FF97",
  lose: "#ff4d4f",

  fontColorPrimary: "#ffffff",
  fontColorSecondary: "#C4C4C4",
  fontColorTertiary: "#808080",

  inverseFontColorPrimary: "#000000",
  inverseFontColorSecondary: "#808080",

  fontSizeS: 12,
  fontSizeM: 14,
  fontSizeL: 16,
  fontSizeXL: 18,
  countfontSize: 16,

  bgColorPrimary: "#000000",
  bgColorSecondary: "rgba(255, 255, 255, 0.1)",
  bgColorTertiary: "#3A3A3A",

  borderColorPrimary: "#808080",

  highlightedText: {
    color: "#ffffff",
    fontWeight: "bold",
  },

  pcMaxWidth: 1376,
  pcMinWidth: 1270,
  xlFlex: "unset",
  xsFlex: "flex",
  leftMargin: 12,
  balanceUnit: {
    height: "unset",
    borderTop: "none",
    lineHeight: "",
    marginRight: 50,
  },
  countmarginLeft: "auto",
  apypadding: "4px 8px",
  actflexDirection: "column",
  invlexDirection: "column",
  actalignItems: "flex-end",
  modalbackground: "#141414",
  contentBackground: "#3a3a3a",
  hismainMargin: "14px -14px -14px -14px",
  mobilebtn: "auto",
  apyLine: "none",
  modalBottom: "24px",
};

export type Theme = typeof theme;

export default theme;
