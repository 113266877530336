import { ButtonType } from "antd/lib/button";
import axios from "axios";
import { BigNumber } from "ethers";
import _ from "lodash";
import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import { hexlify } from "@ethersproject/bytes";
import { keccak256 as solidityKeccak256 } from "@ethersproject/solidity";
import { formatUnits, parseUnits } from "@ethersproject/units";

import { MaxButton } from "../";
import { operations } from "../../api/api";
import { formatDecimal, formatMula } from "../../helpers/format";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useAppDispatch } from "../../redux/store";
import { fetchStrategies } from "../../redux/strategySlice";
import LabelWithPopover from "../LabelWithPopover";
import { ITokenInputChangeEvent } from "../TokenInput";
import ActionTitle from "./ActionTitle";
import ActionModal from "./common/ActionModal";
import ModalExtraInfoRow from "./common/ModalExtraInfoRow";
import ModalResult from "./common/ModalResult";
import ModalTokenInput from "./common/ModalTokenInput";
import { FundExitModalProps } from "./FundModal";

export default function ExitModal({ token, strategy, onClose }: FundExitModalProps): JSX.Element {
  const { address, signer, rpcUrl } = useWeb3Context();
  const [amount, setAmount] = useState("");
  const [errMsg, setErrMsg] = useState<string | undefined>("");
  const [resultMsg, setResultMsg] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const minExit = formatUnits(strategy.minExit || 0, token.decimal);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const exitMutation = useMutation<
    unknown,
    unknown,
    operations["strategyOperation"]["requestBody"]["content"]["application/x-www-form-urlencoded"],
    unknown
  >(data => axios.post(`${rpcUrl}/strategyOperation`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("investmentHistory");
      queryClient.invalidateQueries("strategies");
      setResultMsg("Withdrawal has been submitted");
    },
    onError: e => {
      setErrMsg(_.get(e, "message"));
    },
  });

  const handleAction = async () => {
    let value: BigNumber;
    try {
      value = parseUnits(amount, token.decimal);
    } catch (e) {
      setErrMsg("Input is invalid");
      return;
    }

    if (value.lt(parseUnits(minExit, token.decimal))) {
      setErrMsg(`Please input a number larger than ${minExit}`);
      return;
    }

    if (value.gt(parseUnits(strategy.stTokenAmt || "0", 0))) {
      setErrMsg("You don’t have enough strategy token to withdraw");
      return;
    }

    const timestamp = Date.now();
    const transitionHash = solidityKeccak256(
      ["uint8", "uint32", "uint256", "uint64"],
      [4 /* TRANSITION_TYPE_UNCOMMIT */, strategy.id, value, timestamp],
    );
    if (!signer) {
      return;
    }
    setLoading(true);
    // NOTE: Force personal_sign to work around quirks in Coinbase Wallet and possibly others
    const signature = await signer.provider.send("personal_sign", [hexlify(transitionHash), address.toLowerCase()]);
    setShouldReload(true);
    const data = {
      type: 4,
      strategyId: strategy.id,
      amount: value.toString(), // stToken amount
      timestamp,
      signature,
    };
    exitMutation.mutateAsync(data).finally(() => setLoading(false));
  };

  const handleModalClose = useCallback(() => {
    if (shouldReload) {
      dispatch(fetchStrategies({ address, tokenId: token.id }));
    }
    onClose();
  }, [address, dispatch, onClose, token.id, shouldReload]);

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
    setErrMsg(e.error);
  };

  let content;
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extra;

  if (resultMsg) {
    content = (
      <ModalResult
        title={resultMsg}
        description={
          <span>
            It may take up to 6 hours to finalize your withdrawal from the strategy. You can check the progress in the
            history page.
          </span>
        }
      />
    );
    action = () => history.push("/history/investment");
    actionText = "Check History";
    buttonType = "link";
    extra = [
      <ModalExtraInfoRow left="Strategy Name" right={strategy.name} />,
      <ModalExtraInfoRow left="Withdraw Amount" right={`${formatMula(amount, "")} ${strategy.stTokenName}`} />,
    ];
  } else {
    content = (
      <ModalTokenInput
        description="You can withdraw your asset from this strategy. The withdrawn assets will be credited back to your available L2 balance which can be committed to another strategy or withdrawn to L1."
        amount={amount}
        maxButton={
          <LabelWithPopover
            centerLabel
            isContainLabel={false}
            placement="top"
            label={
              <div style={{ display: "inline-block" }}>
                <MaxButton
                  onClick={() => setAmount(formatDecimal(strategy.stTokenAmt, token.decimal))}
                  value={formatDecimal(strategy.stTokenAmt, token.decimal)}
                  symbol={strategy.stTokenName}
                />
              </div>
            }
          >
            Strategy Token (stToken) is your liquidity token that can be used to redeem the underlying asset along with
            yields back to your available L2 balance.
          </LabelWithPopover>
        }
        symbol={strategy.stTokenName}
        onChange={handleTokenInputChange}
        bottomDescription={`Minimal Withdraw Amount: ${minExit} ${strategy.stTokenName}`}
      />
    );
    action = handleAction;
    actionText = "Withdraw";
    buttonType = "primary";
    extra = [<ModalExtraInfoRow left="Strategy Name" right={strategy.name} />];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Withdraw from Strategy" token={token} />}
      actionText={actionText}
      errMsg={errMsg}
      onCancel={handleModalClose}
      onAction={action}
      actionLoading={loading}
      buttonType={buttonType}
      extra={extra}
    >
      {content}
    </ActionModal>
  );
}
