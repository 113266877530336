import { Card, Empty, Space, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import _ from "lodash";
import { useCallback } from "react";
import Countdown from "react-countdown";
import { createUseStyles } from "react-jss";

import { DownOutlined } from "@ant-design/icons";

import tokens from "../constants/tokens";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchAssets } from "../redux/assetSlice";
import { fetchGlobalInfo } from "../redux/globalInfoSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { saveShow } from "../redux/tokenToggleSlice";
import { Theme } from "../theme";
import LabelWithPopover from "./LabelWithPopover";
import StrategyTable from "./StrategyTable";
import TokenDetail from "./TokenDetail";

const useStyles = createUseStyles((theme: Theme) => ({
  investHeader: {
    display: "flex",
    alignItems: "center",
  },
  tokenText: {
    fontSize: theme.fontSizeXL,
  },
  empty: {
    marginTop: "20%",
  },
  card: {
    "& .ant-card-head-title": {
      paddingBottom: 0,
    },
  },
  top: {
    marginBottom: 40,
  },
  countDown: {
    marginLeft: theme.countmarginLeft,
    color: "#C4C4C4",
    fontSize: theme.countfontSize,
    fontWeight: "normal",
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: 0,
  },
  tag: {
    padding: "4px 12px",
    display: "inline",
    borderRadius: 11,
    background: "black",
    marginLeft: 12,
  },
  mobileInvestDetail: {},
  totlaCount: {
    height: 50,
    width: "100%",
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: 12,
    textAlign: "center",
    lineHeight: "50px",
  },

  totlaCountTitle: {
    fontSize: 14,
    color: "#fff",
    display: "inline-block",
  },
  totlaCountNum: {
    fontSize: 16,
    color: "#00D395",
    display: "inline-block",
    paddingLeft: 5,
  },
  investSelct: {
    margin: "24px 0",
    display: "flex",
  },
  selectpic: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    "& img": {
      width: "100%",
      borderRadius: "50%",
    },
  },
  selectdes: {
    marginLeft: 5,
    marginRight: 10,
  },
  selecttoog: {},
  tvlCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: theme.fontSizeM,
    fontWeight: "bold",
  },
  tvl: {
    marginLeft: 6,
    color: theme.win,
    fontSize: theme.fontSizeL * 1.2,
    fontWeight: 600,
  },
}));

const InvestHeader = ({ token, nextReallocationTime, onCountdownComplete }) => {
  const classes = useStyles();
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const renderCountdown = useCallback(() => {
    if (!nextReallocationTime || nextReallocationTime === "0") {
      return null;
    }
    return (
      <span className={classes.countDown}>
        <LabelWithPopover label="Next aggregated fund allocation in" iconOnLeft>
          Layer2.finance aggregates multiple users&apos; DeFi&apos;s fund allocation intents on L2 Rollup and executes
          them in a single transaction on L1. This timer shows approximate when the next execution will happen.
        </LabelWithPopover>
        <div className={classes.tag}>
          <Countdown
            key={nextReallocationTime}
            date={_.toNumber(nextReallocationTime)}
            daysInHours
            onComplete={onCountdownComplete}
          />
        </div>
      </span>
    );
  }, [classes, nextReallocationTime, onCountdownComplete]);

  return (
    <div className={classes.investHeader}>
      {!isMobile ? (
        <Space>
          <Avatar src={token?.iconUrl} />
          <Typography.Text className={classes.tokenText}>{token?.name}</Typography.Text>
        </Space>
      ) : null}
      {renderCountdown()}
    </div>
  );
};

export default function InvestDetail(): JSX.Element {
  const classes = useStyles();
  const { asset, globalInfo } = useAppSelector(state => state);
  const { assets, selectedIndex } = asset;
  const token = assets[selectedIndex];
  const { nextReallocationTime } = globalInfo;
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const { address } = useWeb3Context();
  const handleCountdownComplete = () => {
    dispatch(fetchAssets(address));
    dispatch(fetchGlobalInfo());
  };
  let tokenName = token?.name;
  // Special case WETH
  if (token?.symbol === "WETH") {
    tokenName = "ETH";
  }
  if (!token) {
    return <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description="Please select a token" />;
  }

  return !isMobile ? (
    <Card
      className={classes.card}
      bodyStyle={{ padding: "32px 16px" }}
      headStyle={{ padding: "0 16px" }}
      title={
        <InvestHeader
          token={token}
          nextReallocationTime={nextReallocationTime}
          onCountdownComplete={handleCountdownComplete}
        />
      }
    >
      <div className={classes.top}>
        <TokenDetail />
      </div>
      <StrategyTable />
    </Card>
  ) : (
    <div className={classes.mobileInvestDetail}>
      <div className={classes.investSelct} onClick={() => dispatch(saveShow({ IsShow: true }))}>
        <div className={classes.selectpic}>
          <img src={token.symbol && tokens[token.symbol].iconUrl} alt="" />
        </div>
        <div className={classes.selectdes}>{tokenName}</div>
        <div className={classes.selecttoog}>
          <DownOutlined style={{ fontSize: "14px" }} />
        </div>
      </div>
      <TokenDetail />
      <StrategyTable />
    </div>
  );
}
