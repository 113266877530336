import { Skeleton } from "antd";
import { FC } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../theme";

interface IProps {
  loading: boolean;
  width?: number;
  height?: number;
}

const useStyles = createUseStyles<string, { height: number | undefined }, Theme>({
  container: {
    height: props => props.height && props.height + 4,
  },
  skeleton: {
    padding: "2px 0",
    "& .ant-skeleton-paragraph": {
      margin: 0,
    },
  },
});

const SingleLineSkeleton: FC<IProps> = props => {
  const { children, loading, width = 150, height } = props;
  const classes = useStyles({ height });
  return (
    <div className={classes.container}>
      <Skeleton active loading={loading} title={false} paragraph={{ rows: 1, width }} className={classes.skeleton}>
        {children}
      </Skeleton>
    </div>
  );
};

export default SingleLineSkeleton;
