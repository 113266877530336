import { Button, Space, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useToggle } from "react-use";

import { DownloadOutlined, DownOutlined, UploadOutlined, UpOutlined } from "@ant-design/icons";
import { BigNumber } from "@ethersproject/bignumber";
import { hexlify } from "@ethersproject/bytes";

import { checkDepositLimit } from "../api/rollupChain";
import { formatDecimal } from "../helpers/format";
import { useCustomContractLoader, useEthBalance, useTokenBalance } from "../hooks";
import { useContractsContext } from "../providers/ContractsContextProvider";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchAssets } from "../redux/assetSlice";
import { ModalName, openModal } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { fetchStrategies } from "../redux/strategySlice";
import { Theme } from "../theme";
import { ERC20 } from "../typechain/ERC20";
import { ERC20__factory } from "../typechain/factories/ERC20__factory";
import LabelWithPopover from "./LabelWithPopover";
import { ActionModal, DepositETHModal, DepositModal, WithdrawModal } from "./modals";
import ReloadButton from "./ReloadButton";
import SingleLineSkeleton from "./SingleLineSkeleton";

const useStyles = createUseStyles<string, unknown, Theme>(theme => ({
  container: {
    background: theme.contentBackground,
    padding: 16,
    borderRadius: 12,
    position: "relative",
  },
  listHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
    justifyContent: "space-between",
    marginBottom: 16,
  },
  top: {
    marginBottom: 20,
    display: theme.xsFlex,
  },
  topRight: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: 2,
  },
  bottom: {
    display: theme.xsFlex,
    marginTop: 10,
  },
  loginHeader: {
    display: "flex",
    margin: 20,
  },
  balance: {
    fontSize: 20,
    margin: 0,
  },
  balanceLgTitle: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeL,
  },
  balanceLg: {
    color: theme.fontColorPrimary,
    fontSize: theme.fontSizeXL,
    fontWeight: "bold",
  },
  balanceUnit: {
    display: theme.xlFlex,
    justifyContent: "space-between",
    height: theme.balanceUnit.height,
    borderBottom: theme.balanceUnit.borderTop,
    lineHeight: theme.balanceUnit.lineHeight,
    marginRight: theme.balanceUnit.marginRight,
    "&:last-child": {
      marginRight: 0,
    },
  },
  balanceSmTitle: {
    color: theme.fontColorSecondary,
  },
  balanceSm: {
    color: theme.fontColorPrimary,
  },
  buttonGroup: {
    marginLeft: "auto",
    display: theme.xlFlex,
    justifyContent: "space-between",
    "@global": {
      ".ant-btn": {
        fontSize: theme.fontSizeS,
        marginLeft: theme.leftMargin,
        width: theme.mobilebtn,
      },
    },
  },
  valueDetail: {
    position: "absolute",
    top: "30px",
    right: "20px",
  },
}));

export default function TokenDetail(): JSX.Element {
  // styles
  const classes = useStyles();
  const theme = useTheme<Theme>();

  // redux related
  const dispatch = useAppDispatch();
  const { asset, strategy } = useAppSelector(state => state);
  const { assets, selectedIndex, loading: loadingAssets } = asset;
  const { loading: loadingStrategies, totalEarning, totalInvestmentValue } = strategy;
  const token = assets[selectedIndex];
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const investmentValue = useMemo(() => BigNumber.from(totalInvestmentValue), [totalInvestmentValue]);

  // token/contract related states
  const { signer, address, provider } = useWeb3Context();
  const {
    contracts: { rollupChain },
  } = useContractsContext();
  const tokenContract = useCustomContractLoader(
    provider,
    (token.address ? hexlify(token.address) : token.address) || "",
    ERC20__factory,
  ) as ERC20 | undefined;
  const [tokenBal, tokenBalLoading, , reloadTokenBal] = useTokenBalance(tokenContract, address);
  const [ethBal, ethBalLoading, , reloadEthBal] = useEthBalance(provider, address);

  // local states
  const [showDeposit, toggleDeposit] = useToggle(false);
  const [showWithdraw, toggleWithdraw] = useToggle(false);
  const [showValueDetail, toggleValueDetail] = useToggle(false);
  const [checkingDepositLimit, setCheckingDepositLimit] = useState(false);
  const [error, setError] = useState("");

  const reloadAll = () => {
    dispatch(fetchStrategies({ address, tokenId: token.id }));
    dispatch(fetchAssets(address));
    reloadTokenBal();
    reloadEthBal();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showDeposit, showWithdraw]);
  const minDeposit = useMemo(() => {
    if (strategy.strategies.length) {
      const ret = strategy.strategies.reduce((minFund, strat) => {
        const stratMinFund = BigNumber.from(strat.minFund || 0);
        return stratMinFund.lt(minFund) ? stratMinFund : minFund;
      }, BigNumber.from(strategy.strategies[0].minFund));
      return formatDecimal(ret, token.decimal);
    }
    return undefined;
  }, [strategy.strategies, token.decimal]);

  let depositModal: JSX.Element;
  let l1Balance: BigNumber;
  if (token.symbol === "ETH") {
    depositModal = <DepositETHModal token={token} onClose={toggleDeposit} minDeposit={minDeposit} onError={setError} />;
    l1Balance = ethBal;
  } else {
    depositModal = <DepositModal token={token} onClose={toggleDeposit} minDeposit={minDeposit} onError={setError} />;
    l1Balance = tokenBal;
  }

  if (!signer) {
    return (
      <div className={classes.container}>
        <div className={classes.listHeader}>
          <Typography.Title level={5}>My Assets Overview</Typography.Title>
        </div>
        <Space className={classes.loginHeader} direction="vertical" align="center">
          <div>Connect your wallet to start</div>
          <Button type="primary" onClick={() => dispatch(openModal(ModalName.provider))}>
            Connect to Wallet
          </Button>
        </Space>
      </div>
    );
  }

  const handleDepositClick = async () => {
    setCheckingDepositLimit(true);
    try {
      const result = await checkDepositLimit(rollupChain, token.address);
      if (result.isDepositLimitReached) {
        setError(`Sorry the ${token.symbol} pool currently doesn’t accept new deposits as the maximum pool cap has been reached.
        The cap will be raised soon. Please stay tuned.`);
      } else {
        toggleDeposit();
      }
    } finally {
      setCheckingDepositLimit(false);
    }
  };

  return (
    <div>
      <div className={classes.listHeader}>
        <Typography.Title level={5}>My Assets Overview</Typography.Title>
        <ReloadButton onClick={reloadAll} />
      </div>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>
            <div className={classes.balanceLgTitle}>L2 Total Asset Value</div>
            <SingleLineSkeleton loading={loadingAssets || loadingStrategies} height={theme.fontSizeXL}>
              <div className={classes.balanceLg}>
                {formatDecimal(investmentValue.add(BigNumber.from(token.amount || 0)), token.decimal)} {token.symbol}
              </div>
            </SingleLineSkeleton>
          </div>
          {isMobile ? (
            <div className={classes.valueDetail}>
              {!showValueDetail ? (
                <DownOutlined onClick={toggleValueDetail} />
              ) : (
                <UpOutlined onClick={toggleValueDetail} />
              )}
            </div>
          ) : (
            <div className={classes.topRight}>
              <div className={classes.balanceSmTitle}>L1 Balance in Wallet</div>
              <SingleLineSkeleton
                loading={token.symbol === "ETH" ? ethBalLoading : tokenBalLoading}
                height={theme.fontSizeL}
              >
                <div className={classes.balanceSm}>
                  {formatDecimal(l1Balance, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>
          )}
        </div>

        <div className={classes.bottom}>
          {(!isMobile || showValueDetail) && [
            <div className={classes.balanceUnit} key="1">
              <LabelWithPopover label={<span className={classes.balanceSmTitle}>Available L2 balance</span>}>
                This is your L2 balance available to commit to DeFi strategies or withdraw to your L1 wallet. You can
                increase your L2 balance by depositing from your L1 wallet or withdrawing funds from your portfolio
                strategies.
              </LabelWithPopover>
              <SingleLineSkeleton loading={loadingAssets || loadingStrategies} height={theme.fontSizeL}>
                <div className={classes.balanceSm}>
                  {formatDecimal(token.amount, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>,
            <div className={classes.balanceUnit} key="2">
              <LabelWithPopover label={<span className={classes.balanceSmTitle}>Investment Asset Value</span>}>
                This is the total asset value underlying the strategies you have invested. Note that the value is
                estimated based on your current stToken balance/price and may not be accurate.
              </LabelWithPopover>
              <SingleLineSkeleton loading={loadingAssets || loadingStrategies} height={theme.fontSizeL}>
                <div className={classes.balanceSm}>
                  {formatDecimal(investmentValue, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>,
            <div className={classes.balanceUnit} key="3">
              <LabelWithPopover label={<span className={classes.balanceSmTitle}>Total Earning</span>}>
                This is your yields harvested from your portfolio strategies so far. Earnings are updated every 24
                hours.
              </LabelWithPopover>
              <SingleLineSkeleton loading={loadingAssets || loadingStrategies} height={theme.fontSizeL}>
                <div className={classes.balanceSm}>
                  {formatDecimal(totalEarning, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>,
          ]}
          {isMobile && (
            <div style={{ marginTop: 20 }}>
              <div className={classes.balanceSmTitle}>L1 Balance in Wallet</div>
              <SingleLineSkeleton
                loading={token.symbol === "ETH" ? ethBalLoading : tokenBalLoading}
                height={theme.fontSizeL}
              >
                <div className={classes.balanceLg}>
                  {formatDecimal(l1Balance, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>
          )}
          <div className={classes.buttonGroup} style={{ marginTop: isMobile ? 20 : 0 }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleDepositClick}
              disabled={loadingAssets}
              loading={checkingDepositLimit}
              style={isMobile ? { fontWeight: "bold" } : { fontWeight: "normal" }}
            >
              Deposit to L2
            </Button>
            <Button
              style={isMobile ? { fontWeight: "bold" } : { fontWeight: "normal" }}
              type="primary"
              icon={<UploadOutlined />}
              onClick={toggleWithdraw}
              disabled={loadingAssets}
            >
              Withdraw to L1
            </Button>
          </div>
        </div>

        <ActionModal visible={!!error} onCancel={() => setError("")} onAction={() => setError("")}>
          {error}
        </ActionModal>

        {showDeposit && depositModal}
        {showWithdraw && <WithdrawModal token={token} onClose={toggleWithdraw} />}
      </div>
    </div>
  );
}
