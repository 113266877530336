import { Avatar, Card, List } from "antd";
import _ from "lodash";
import { FC } from "react";
import { createUseStyles } from "react-jss";

import { CloseOutlined } from "@ant-design/icons";

import { components } from "../api/api";
import tokens from "../constants/tokens";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { saveShow } from "../redux/tokenToggleSlice";
import { Theme } from "../theme";

const useStyles = createUseStyles((theme: Theme) => ({
  card: {
    background: theme.modalbackground,
    "@global": {
      ".ant-list-item": {
        padding: "16px 12px",
      },
      ".ant-list-item-meta-title": {
        fontSize: 16,
        marginBottom: 0,
      },
      ".ant-list-item-meta-description": {
        fontSize: 12,
      },
    },
    "&.ant-card": {
      height: "100%",
    },
    "& .ant-card-body": {
      padding: 0,
      overflow: "hidden",
    },

    "& .ant-list-item": {
      border: "none",
    },

    "& .ant-list-item-meta": {
      alignItems: "center",
    },
    "& .ant-card-head-title": {
      padding: "24px 0",
    },
  },
  item: {
    cursor: "pointer",
    overflow: "hidden",
    "&:hover": {
      background: theme.bgColorSecondary,
      transition: "ease 0.2s",
    },
  },
  activeItem: {
    composes: ["item"],
    background: theme.bgColorTertiary,
    transition: "ease 0.2s",
  },
  cardmodal: {
    position: "fixed",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
  },
  modalcover: {
    height: "5%",
    width: "100%",
    background: "rgba(0,0,0, 0.4)",
  },
  modalcontent: {
    width: "100%",
    height: "95%",
    borderRadius: "4px 0",
  },
  closeIcon: {
    fontSize: 20,
    color: "#fff",
    position: "absolute",
    top: 60,
    right: 20,
    zIndex: 3,
  },
}));

interface IProps {
  onSelectToken: (tokenId: number | string) => void;
}

const TokenList: FC<IProps> = ({ onSelectToken }) => {
  const classes = useStyles();
  const { assets, selectedIndex } = useAppSelector(state => state.asset);
  const selectedToken = assets[selectedIndex];
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const renderTokenItem = (token: components["schemas"]["Asset"]) => {
    let tokenName = token.name;
    let tokenSymbol = token.symbol;
    // Special case WETH
    if (token.symbol === "WETH") {
      tokenName = "ETH";
      tokenSymbol = "ETH";
    }
    return (
      <List.Item
        className={_.get(selectedToken, "id") === token.id ? classes.activeItem : classes.item}
        onClick={() => onSelectToken(token.id)}
      >
        <List.Item.Meta
          avatar={<Avatar size="large" src={token.symbol && tokens[token.symbol].iconUrl} />}
          title={tokenName}
          description={tokenSymbol}
        />
      </List.Item>
    );
  };

  return (
    <div className={isMobile ? classes.cardmodal : ""}>
      <div className={classes.modalcover} />
      <div className={classes.modalcontent}>
        {isMobile && (
          <CloseOutlined className={classes.closeIcon} onClick={() => dispatch(saveShow({ IsShow: false }))} />
        )}
        <Card
          className={classes.card}
          title="Supported Tokens"
          bodyStyle={{ paddingBottom: 12 }}
          headStyle={{ paddingLeft: 12, paddingRight: 12 }}
        >
          <List itemLayout="horizontal" size="small" dataSource={assets} renderItem={renderTokenItem} />
        </Card>
      </div>
    </div>
  );
};

export default TokenList;
