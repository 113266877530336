import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BigNumber } from "ethers";
import { components } from "../api/api";
import { getStrategies } from "../api/strategy";

interface IStrategySlice {
  strategies: components["schemas"]["Strategy"][];
  loading: boolean;

  // derived states
  totalInvestmentValue: string; // BigNumber.toString()
  totalEarning: string; // BigNumber.toString()
}

const initialState: IStrategySlice = {
  strategies: [],
  loading: false,

  // derived states
  totalInvestmentValue: "0",
  totalEarning: "0",
};

const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    saveStrategies: (state, { payload }: PayloadAction<components["schemas"]["Strategy"][]>) => {
      state.strategies = payload;
      state.totalInvestmentValue = state.strategies
        .reduce(
          (total, current) => (current.estimatedValue ? total.add(BigNumber.from(current.estimatedValue)) : total),
          BigNumber.from(0),
        )
        .toString();
      const totalEarning = state.strategies.reduce(
        (total, current) => (current.earnings ? total.add(BigNumber.from(current.earnings)) : total),
        BigNumber.from(0),
      );
      // handle special case where earnings interger calculation can cause -1 earning up on initial strategy deposit
      // checking total earning < 10 can torlerate up to 10 simultaneous initial strategy deposits.
      if (totalEarning.lte(10)) {
        state.totalEarning = BigNumber.from(0).toString();
      } else {
        state.totalEarning = totalEarning.toString();
      }
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const { saveStrategies, setLoading } = strategySlice.actions;

export const fetchStrategies = createAsyncThunk(
  "strategy/fetchStrategies",
  async ({ tokenId, address }: { tokenId: number; address: string }, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    const strategies = await getStrategies(tokenId, address).catch(_ => null);
    if (strategies) {
      thunkApi.dispatch(saveStrategies(strategies));
    }
    thunkApi.dispatch(setLoading(false));
  },
);

export default strategySlice.reducer;
