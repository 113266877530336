import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPersistedTx {
  ownerAddress: string;
  tnId: number;
  hash: string;
  assetAmt?: string;
  assetSymbol?: string;
  executeTimestamp: string;
  acceptTimestamp: string;
}

export interface IPersistedTxMap {
  [hash: string]: IPersistedTx;
}

export interface IWithdrawConfirmationTimes {
  [address: string]: {
    [token: string]: number;
  };
}

interface IPersistedTxState {
  depositTxs: IPersistedTxMap;
  withdrawConfirmationTimes: IWithdrawConfirmationTimes;
}

const initialState: IPersistedTxState = {
  depositTxs: {},
  withdrawConfirmationTimes: {},
};

const persistedTxSlice = createSlice({
  name: "persistedTx",
  initialState,
  reducers: {
    addDepositTx: (state, { payload }: PayloadAction<IPersistedTx>) => {
      state.depositTxs[payload.hash] = payload;
    },
    removeDepositTx: (state, { payload: hash }: PayloadAction<string>) => {
      delete state.depositTxs[hash];
    },
    setWithdrawConfirmationTime: (
      state,
      { payload }: PayloadAction<{ address: string; token: string; time: number }>,
    ) => {
      const { address, token, time } = payload;
      if (!state.withdrawConfirmationTimes[address]) {
        state.withdrawConfirmationTimes[address] = {};
      }
      state.withdrawConfirmationTimes[address][token] = time;
    },
  },
});

export const { addDepositTx, removeDepositTx, setWithdrawConfirmationTime } = persistedTxSlice.actions;

export const actionTypes = Object.values(persistedTxSlice.actions).map(action => action.type);

export default persistedTxSlice.reducer;
