import { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";

interface IProps {
  left: ReactNode;
  right: ReactNode;
}

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px 0",
  },
});

const ModalExtraInfoRow: FC<IProps> = props => {
  const { left, right } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
};

export default ModalExtraInfoRow;
