import { usePoller } from "eth-hooks";
import { useCallback, useEffect, useState } from "react";
import { NETWORKS } from "../constants/network";

const DEFAULT_POLL_TIME = 2 * NETWORKS.mainnet.blockTime;

export default function useTypedContractReader<T>(
  method: () => Promise<T> | undefined,
  pollTime = DEFAULT_POLL_TIME,
  onChange?: (arg: T) => void,
): T | undefined {
  let adjustedPollTime = DEFAULT_POLL_TIME;
  if (pollTime) {
    adjustedPollTime = pollTime;
  }
  const [value, setValue] = useState<T>();

  useEffect(() => {
    if (typeof onChange === "function") {
      setTimeout(arg => onChange(arg), 1);
    }
  }, [value, onChange]);

  const callMethod = useCallback(async () => {
    if (method) {
      try {
        const newValue = await method();
        if (newValue !== value) {
          setValue(newValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [method, value]);

  usePoller(() => {
    callMethod();
  }, adjustedPollTime);

  useEffect(() => {
    callMethod();
  }, [method, callMethod]);

  return value;
}
