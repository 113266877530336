import { FC, ReactNode } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { CheckCircleFilled } from "@ant-design/icons";

import { Theme } from "../../../theme";

interface IProps {
  title: ReactNode;
  description: ReactNode;
}

const useStyles = createUseStyles<string, unknown, Theme>(theme => ({
  container: {},
  description: {
    color: theme.fontColorSecondary,
    lineHeight: 1.3,
  },
  icon: {
    marginTop: 40,
  },
}));

const ModalResult: FC<IProps> = props => {
  const { title, description } = props;
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.description}>{description}</div>
      <div className={classes.icon}>
        <CheckCircleFilled style={{ color: theme.successColor, fontSize: 32 }} />
      </div>
    </div>
  );
};

export default ModalResult;
