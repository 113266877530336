import { ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC } from "react";
import { createUseStyles } from "react-jss";

interface IProps {
  value: string;
  onClick: () => void;
  loading?: boolean;
  error?: string;
  onReload?: () => void;
  symbol?: string;
}

const useStyles = createUseStyles({
  balanceText: {
    textDecoration: "underline",
    fontSize: 12,
  },
  reloadText: {
    fontSize: 12,
  },
});

const MaxButton: FC<IProps> = props => {
  const classes = useStyles();
  const { onClick, value, loading, error, onReload, symbol = "" } = props;

  if (error) {
    return (
      <Button size="small" type="text" icon={<ReloadOutlined />} onClick={onReload} className={classes.reloadText}>
        Reload Balance
      </Button>
    );
  }

  return (
    <Button size="small" type="text" loading={loading} onClick={onClick}>
      <span className={classes.balanceText}>
        {loading ? "Fetching balance..." : `Max Amount${value && ":"} ${value} ${symbol}`}
      </span>
    </Button>
  );
};

export default MaxButton;
