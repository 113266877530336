import { FC } from "react";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createUseStyles } from "react-jss";

import { Theme } from "../theme";

interface IProps {
  dataList: any[];
  columns: IColumn[];
}
interface IColumn {
  title: any;
  render: any;
  dataIndex: any;
}
const useStyles = createUseStyles((theme: Theme) => ({
  tableList: {
    padding: 16,
    position: "relative",
    background: theme.contentBackground,
    borderRadius: "12px",
    marginTop: 20,
  },
  tableListItem: {
    height: 50,
    lineHeight: "50px",
    borderBottom: `1px solid ${theme.borderColorPrimary}`,
    display: "flex",
    justifyContent: "space-between",
  },
  tableListItemLeft: {},
  tableListItemRight: {
    fontWeight: "bold",
  },
  tableListItemBtn: {
    width: "100%",
    paddingTop: 10,
    "& button": {
      width: "49%",
      fontWeight: "bold",
    },
  },
}));
const ContentList: FC<IProps> = props => {
  const classes = useStyles();
  const { dataList, columns } = props;

  return (
    <div>
      {dataList?.map(item => {
        return (
          <div className={classes.tableList} key={item.key}>
            {columns.map(column => {
              return column.title ? (
                <div className={classes.tableListItem} key={column.dataIndex}>
                  <div className={classes.tableListItemLeft}>{column.title}</div>
                  <div className={classes.tableListItemRight}>{column.render("", item)}</div>
                </div>
              ) : (
                <div className={classes.tableListItem} key={column.dataIndex}>
                  <div className={classes.tableListItemBtn}>{column.render("", item)}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default ContentList;
