import { RightOutlined } from "@ant-design/icons";
import { parseEther } from "@ethersproject/units";
import { Result, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useEthBalance } from "../../hooks";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import ActionTitle from "./ActionTitle";

export interface FaucetTokenInfo {
  address: string;
  symbol: string;
}

const useStyles = createUseStyles({
  entry: {
    border: "0.5px solid #C4C4C4",
    borderRadius: 12,
    width: "100%",
    height: 60,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  entryInfo: {
    marginLeft: 15,
  },
  arrow: {
    marginRight: 15,
    color: "#A0A0A0",
  },
  link: {
    color: ["#CA9979", "!important"],
  },
});

interface FaucetModalProps {
  tokenInfos: FaucetTokenInfo[];
  onClose: () => void;
}

function Entry({ text, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.entry} onClick={onClick}>
      <Space className={classes.entryInfo}>
        <Text>{text}</Text>
      </Space>
      <RightOutlined className={classes.arrow} />
    </div>
  );
}

function FaucetResultModal({ resultText, onCancel }) {
  return (
    <Modal closable visible width={360} onCancel={onCancel} footer={null}>
      <Result status="success" title={resultText} />
    </Modal>
  );
}

export default function FaucetModal({ tokenInfos, onClose }: FaucetModalProps): JSX.Element {
  const {
    contracts: { faucet },
    transactor,
  } = useContractsContext();
  const { provider, address } = useWeb3Context();
  const [ethBalance] = useEthBalance(provider, address);
  const [resultMsg, setResultMsg] = useState("");
  const tokenAddresses = tokenInfos.map(info => info.address);

  const drip = async () => {
    if (
      !transactor ||
      !faucet ||
      !tokenInfos ||
      tokenInfos.length === 0 ||
      !ethBalance ||
      parseEther(ethBalance.toString()).eq(0)
    ) {
      return;
    }
    try {
      const dripTx = await transactor(faucet.drip(tokenAddresses));
      await dripTx.wait();
      setResultMsg("Received Tokens");
    } catch (e) {
      // Handled by transactor
    }
  };

  if (resultMsg) {
    return <FaucetResultModal resultText={resultMsg} onCancel={onClose} />;
  }

  const tokenSymbols = tokenInfos.map(info => info.symbol);
  const tokensText = "Test Tokens (" + tokenSymbols.join(", ") + ")";
  return (
    <Modal
      closable
      visible
      width={500}
      title={<ActionTitle title="Get From Faucets" token={undefined} />}
      onCancel={onClose}
      footer={null}
    >
      <Entry
        text="Test ETH"
        onClick={(e: Event) => {
          e.preventDefault();
          const faucetUrl = process.env.REACT_APP_ETH_FAUCET_URL;
          if (faucetUrl) {
            window.open(faucetUrl, "_blank");
          }
        }}
      />
      <Entry text={tokensText} onClick={() => drip()} />
    </Modal>
  );
}
