import { Table } from "antd";
import { FC } from "react";
import { createUseStyles } from "react-jss";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from "@ant-design/icons";

import PageFlipper from "./PageFlipper";

interface IProps {
  loading: boolean;
  columns: any[];
  dataSource: any[];
  currentPage?: number; // 0 based
  hasMore?: boolean;
  onPageChange?: (toPage: number) => void;
  className?: string;
}

const useStyles = createUseStyles({
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 12,
  },
});

const CustomTable: FC<IProps> = props => {
  const { columns, dataSource, loading, currentPage, hasMore = false, onPageChange = () => {}, className = "" } = props;

  const classes = useStyles();

  return (
    <div className={className}>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined style={{ fontSize: 40 }} />,
        }}
        locale={{ emptyText: <div style={{ height: 200 }} /> }}
      />
      {currentPage !== undefined ? (
        <div className={classes.pagination}>
          <PageFlipper page={currentPage} hasMore={hasMore} onPageChange={onPageChange} />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
