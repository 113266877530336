import { Card, Menu } from "antd";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";

import { MainchainHistory, StrategyHistory } from "../components";
import { useAppSelector } from "../redux/store";
import { Theme } from "../theme";

const useStyles = createUseStyles((theme: Theme) => ({
  header: {},
  menu: {
    background: "none",
    display: "flex",
    alignItems: "center",
    margin: "0 -14px",

    "& .ant-menu-item a": {
      color: "rgba(255, 255, 255, 0.4)",
      fontWeight: "bold",
      fontSize: 16,
      display: "inline-block",
      paddingBottom: 14,
      "&:hover": {
        color: theme.fontColorSecondary,
      },
    },

    "& .ant-menu-item-selected a": {
      color: "#ffffff",
    },
  },
  mobilemenu: {
    background: "none",
    display: "flex",
    alignItems: "center",
    margin: "0 -14px",

    "& .ant-menu-item a": {
      color: "rgba(255, 255, 255, 0.4)",
      fontWeight: 600,
      fontSize: 14,
      display: "inline-block",
      paddingBottom: 14,
      "&:hover": {
        color: theme.fontColorSecondary,
      },
    },

    "& .ant-menu-item-selected a": {
      color: "#ffffff",
    },
  },
  mobileHeader: {
    display: "flex",
  },
}));

export default function History(): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const [route, setRoute] = useState("");
  const { isMobile } = useAppSelector(state => state.windowWidth);

  useEffect(() => {
    const segments = location.pathname.split("/").filter(p => p);
    setRoute(segments[1]);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Card bodyStyle={isMobile ? { padding: 10, background: "#1a1a1a" } : { padding: 32 }}>
      {isMobile ? (
        <div className="mobileBody">
          <div className={classes.header}>
            <Menu className={classes.mobilemenu} selectedKeys={[route]} mode="horizontal" style={{ lineHeight: 1 }}>
              <Menu.Item key="mainchain" style={{ width: "50%", margin: 0, textAlign: "center", fontSize: 14 }}>
                <Link to="/history/mainchain">Layer1 Transaction</Link>
              </Menu.Item>
              <Menu.Item key="investment" style={{ width: "50%", margin: 0, textAlign: "center", fontSize: 14 }}>
                <Link to="/history/investment">Layer2 Transaction</Link>
              </Menu.Item>
            </Menu>
          </div>
          <Switch>
            <Route path="/history/mainchain">
              <MainchainHistory />
            </Route>
            <Route path="/history/investment">
              <StrategyHistory />
            </Route>
            <Redirect exact from="/history" to="/history/mainchain" />
          </Switch>
        </div>
      ) : (
        <div>
          <div className={classes.header}>
            <Menu className={classes.menu} selectedKeys={[route]} mode="horizontal" style={{ lineHeight: 1 }}>
              <Menu.Item key="mainchain">
                <Link to="/history/mainchain">Layer1 Deposit and Withdrawal</Link>
              </Menu.Item>
              <Menu.Item key="investment">
                <Link to="/history/investment">Layer2 Transaction</Link>
              </Menu.Item>
            </Menu>
          </div>
          <Switch>
            <Route path="/history/mainchain">
              <MainchainHistory />
            </Route>
            <Route path="/history/investment">
              <StrategyHistory />
            </Route>
            <Redirect exact from="/history" to="/history/mainchain" />
          </Switch>
        </div>
      )}
    </Card>
  );
}
