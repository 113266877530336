import { Button, Modal } from "antd";
import { ButtonType } from "antd/lib/button";
import { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../../theme";

const useStyles = createUseStyles((theme: Theme) => ({
  footerExtra: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  alert: {
    color: ["#FC5656", "!important"],
    textAlign: "center",
    display: "block",
    marginBottom: 6,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 40,
    padding: "0 16px",
  },
  list: {
    width: "100%",
    paddingBottom: 40,
  },
  listEntry: {
    borderBottom: `1px solid ${theme.borderColorPrimary}`,
    "&:first-child": {
      borderTop: `1px solid ${theme.borderColorPrimary}`,
    },
  },
  button: {
    marginTop: "auto",
  },
}));

interface IProps {
  title?: ReactNode;
  errMsg?: string;
  visible?: boolean;
  actionText?: string;
  actionLoading?: boolean;
  actionDisabled?: boolean;
  onAction?: () => void;
  onCancel?: () => void;
  footerExtra?: ReactNode;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  buttonType?: ButtonType;
  extra?: ReactNode[];
}

const ActionModal: FC<IProps> = ({
  children,
  title,
  errMsg,
  visible,
  actionText,
  actionLoading,
  actionDisabled,
  onAction,
  onCancel,
  footerExtra = undefined,
  buttonType = "primary",
  extra = [],
}) => {
  const classes = useStyles();

  return (
    <Modal closable title={title ?? <div />} visible={visible} width={360} onCancel={onCancel} footer={null}>
      <div className={classes.content}>{children}</div>
      {extra?.length ? (
        <div className={classes.list}>
          {extra.map((child, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={classes.listEntry}>
                {child}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className={classes.footerExtra}>
        {footerExtra}
        {errMsg && <span className={classes.alert}>{errMsg}</span>}
      </div>
      <Button
        type={buttonType}
        size="large"
        block
        loading={actionLoading}
        onClick={onAction}
        className={classes.button}
        disabled={actionDisabled}
      >
        {actionText || "OK"}
      </Button>
    </Modal>
  );
};

export default ActionModal;
