import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import { FC } from "react";
import { createUseStyles } from "react-jss";

import { ReloadOutlined } from "@ant-design/icons";

import { Theme } from "../theme";

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    "&.ant-btn": {
      border: "none",
      background: theme.bgColorPrimary,

      "&:focus, &:hover": {
        color: theme.fontColorSecondary,
      },
    },
  },
}));

const ReloadButton: FC<ButtonProps> = props => {
  const { children, className, ...restProps } = props;
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button className={classNames(classes.button, className)} icon={<ReloadOutlined />} {...restProps}>
      {children}
    </Button>
  );
};

export default ReloadButton;
