import { Button, Spin, Table, Typography } from "antd";
import _ from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

import { LoadingOutlined } from "@ant-design/icons";

import { components } from "../api/api";
import { useContractsContext } from "../providers/ContractsContextProvider";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchMainchainHistory } from "../redux/mainchainHistorySlice";
import { removeDepositTx, setWithdrawConfirmationTime } from "../redux/persistedTxSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Theme } from "../theme";
import LabelWithPopover from "./LabelWithPopover";
import { ActionModal } from "./modals";
import ActionTitle from "./modals/ActionTitle";
import PageFlipper from "./PageFlipper";
import ReloadButton from "./ReloadButton";
import TokenGain from "./TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    margin: theme.hismainMargin,
    "@global": {
      ".ant-table": {
        fontSize: theme.fontSizeL,
      },
    },
  },
  refreshButton: {
    position: "absolute",
    right: 10,
    top: 20,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    "& span": {
      color: ["#c4c4c4", "!important"],
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 12,
  },
  subDescription: {
    fontSize: theme.fontSizeS,
  },
  mainchainHistory: {
    width: "100%",
    position: "relative",
  },
  historyList: {},
  historyListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 80,
    borderBottom: "1px solid #808080",
  },
  ListItemLeft: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ListItemRight: {
    textAlign: "right",
    fontSize: "16px",
    color: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemTop: {
    lineHeight: 1,
  },
  itemTopdesp: {
    fontSize: "14px",
    color: "#fff",
    lineHeight: 1,
  },
  itemTopval: {
    fontSize: "14px",
    color: "#00D395",
    marginLeft: 8,
    lineHeight: 1,
  },
  itemBottom: {
    fontSize: "12px",
    color: "#c4c4c4",
    marginTop: 4,
    lineHeight: 1,
  },
  reloadbtn: {
    position: "fixed",
    top: 47,
    right: 0,
    zIndex: 3,
  },
}));

export default function MainchainHistory(): JSX.Element {
  const classes = useStyles();

  // blockchain states
  const { address, provider } = useWeb3Context();
  const {
    contracts: { rollupChain },
    transactor,
  } = useContractsContext();

  // redux
  const { persistedTx, mainchainHistory } = useAppSelector(state => state);
  const { withdrawConfirmationTimes, depositTxs } = persistedTx;
  const { entries, hasMore, loading } = mainchainHistory;
  const dispatch = useAppDispatch();

  // local states
  const [selectedRecord, setSelectedRecord] = useState<components["schemas"]["MainchainHistoryEntry"]>();
  const [errMsg, setErrMsg] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { isMobile } = useAppSelector(state => state.windowWidth);

  const prependL1Txs = (mainchainHisotoryEntries: components["schemas"]["MainchainHistoryEntry"][]) => {
    const txMapped: components["schemas"]["MainchainHistoryEntry"][] = Object.values(depositTxs)
      .filter(tx => tx.ownerAddress === address)
      .map(tx => {
        const { assetAmt, assetSymbol, acceptTimestamp, executeTimestamp, tnId } = tx;
        return {
          tnId, // HACK: since locally generated history doesn't have tnId, using timestamp instead
          action: "DEPOSIT",
          assetAmt,
          assetSymbol,
          status: "PENDING",
          acceptTimestamp,
          executeTimestamp,
        };
      });
    txMapped.sort((a, b) => (a.tnId && b.tnId ? b.tnId - a.tnId : 0)); // put latest history entry at front
    return txMapped.concat(mainchainHisotoryEntries);
  };

  // For each locally persisted pending deposits that don't have 5 block confirmations yet,
  // wait for 6 confirmations to happen then remove locally persisted tx.
  // The reason of adding one extra confirmation here is to get around the issue that by
  // the time frontend observes 5 confirmations, backend may not, so that the pending deposit
  // could disappear for a moment
  useEffect(() => {
    if (!provider || currentPage !== 0 || !depositTxs) {
      return;
    }
    Object.values(depositTxs).forEach(tx => {
      provider.waitForTransaction(tx.hash, 8).then(_tx => {
        console.log(`saw the 8th confirmation of tx ${tx.hash}, removing it from cache`);
        dispatch(removeDepositTx(tx.hash));
        dispatch(fetchMainchainHistory({ address, page: 0 }));
      });
    });
  }, [provider, depositTxs, dispatch, address, currentPage]);

  // Use completed deposits' hash to remove locally persisted pending deposit history entries.
  // This effect works together with the above one to ensure that as the frontend waits for
  // the 6th block confirmation, if backend already observed 5 confirmations, the cached deposit
  // txs can be removed directly to avoid showing both PENDING and COMPLETED records of the same tx
  useEffect(() => {
    entries.forEach(entry => {
      if (!entry.txhash || entry.action !== "DEPOSIT" || entry.status !== "COMPLETED") {
        return;
      }
      if (depositTxs[entry.txhash]) {
        console.log(`tx ${entry.txhash} is in COMPLETED status, removing it from cache`);
        dispatch(removeDepositTx(entry.txhash));
      }
    });
  }, [depositTxs, dispatch, entries]);

  useEffect(() => {
    if (!address) {
      return;
    }
    dispatch(fetchMainchainHistory({ address, page: currentPage }));
  }, [address, currentPage, dispatch]);

  const handleConfirm = async () => {
    try {
      if (!rollupChain) {
        return;
      }
      const assetSymbol = _.get(selectedRecord, "assetSymbol");
      const assetAddress = _.get(selectedRecord, "assetAddress");
      if (!transactor || !assetSymbol || !assetAddress) {
        return;
      }
      setConfirmLoading(true);
      if (assetSymbol === "ETH") {
        await transactor(rollupChain.withdrawETH(address, assetAddress));
      } else {
        await transactor(rollupChain.withdraw(address, assetAddress));
      }
      // persist the time of the this withdraw confirmation action
      const confirmTime = new Date().getTime();
      dispatch(setWithdrawConfirmationTime({ address, token: assetSymbol, time: confirmTime }));
      setConfirmed(true);
    } catch (e) {
      setErrMsg(_.get(e, "error.message"));
    } finally {
      setConfirmLoading(false);
    }
  };

  const mapDataToCols = useCallback((entry: components["schemas"]["MainchainHistoryEntry"]) => {
    let time: string | undefined;

    if (entry.executeTimestamp && entry.executeTimestamp !== "0") {
      time = entry.executeTimestamp;
    } else if (entry.commitTimestamp && entry.commitTimestamp !== "0") {
      time = entry.commitTimestamp;
    } else if (entry.acceptTimestamp && entry.acceptTimestamp !== "0") {
      time = entry.acceptTimestamp;
    }
    return {
      key: entry.tnId,
      amount: {
        action: entry.action,
        assetAmt: entry.assetAmt,
        assetSymbol: entry.assetSymbol,
      },
      action: entry.action,
      time,
      status: entry,
    };
  }, []);
  const changeStatus = record => {
    if (!address) {
      return null;
    }
    if (record.status?.status === "INTENT_CONFIRMED" && record.status?.action === "WITHDRAW") {
      const wct = withdrawConfirmationTimes[address];
      if (wct) {
        const lastWithdrawConfirmationTime =
          record.status?.assetSymbol && withdrawConfirmationTimes[address][record.status?.assetSymbol];
        const executeTimestamp = parseInt(record.status.executeTimestamp || "0", 10);
        if (lastWithdrawConfirmationTime && lastWithdrawConfirmationTime >= executeTimestamp) {
          return (
            <LabelWithPopover label="Confirming">
              Your withdrawal is being confirmed on L1. Please allow a few minutes before the funds are credited to your
              L1 wallet.
            </LabelWithPopover>
          );
        }
      }
      return (
        <Button
          type="primary"
          onClick={() => {
            setConfirmed(false);
            setSelectedRecord(record.status);
          }}
        >
          Confirm
        </Button>
      );
    }

    switch (record.status?.status) {
      case "PENDING":
        return record.status.action === "DEPOSIT" ? (
          <LabelWithPopover label="Confirming">
            Your deposit is being confirmed on-chain. Please allow 5 block confirmations (3-5 minutes) for your L2
            balance to be credited.
          </LabelWithPopover>
        ) : (
          <LabelWithPopover label="Confirming">
            <p>Your fund is being transferred from L2 to L1, which might take 2-8 hours to complete.</p>
            <p>
              NOTE: In order to receive the fund in your L1 wallet, you will need to
              <span style={{ fontWeight: "bold" }}> manually confirm</span> the withdrawal again 2-8 hours later on this
              page.
            </p>
          </LabelWithPopover>
        );
      case "COMPLETED":
        return "Completed";
      default:
        return "Error";
    }
  };
  const columns = useMemo(
    () => [
      {
        title: <span>Transaction</span>,
        dataIndex: "amount",
        render: (e, record) => {
          const sign = record.amount.action === "DEPOSIT" ? "" : "-";
          return <TokenGain formattedAmount={sign + record.amount.assetAmt} symbol={record.amount.assetSymbol} />;
        },
      },
      {
        title: <span>Action</span>,
        dataIndex: "action",
        render: (e, record) => {
          switch (record.action) {
            case "DEPOSIT":
              return "Deposit to L2";
            case "WITHDRAW":
              return "Withdraw to L1";
            default:
              return "Unknown";
          }
        },
      },
      {
        title: <span>Time</span>,
        dataIndex: "time",
        render: (e, record) => {
          if (!record.time || record.time === "0") {
            return "--";
          }
          const epoch = _.toNumber(record.time);
          return new Date(epoch).toLocaleString();
        },
      },
      {
        title: <span>Status</span>,
        dataIndex: "status",
        render: (e, record) => changeStatus(record),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address, classes.subDescription, withdrawConfirmationTimes, changeStatus],
  );

  const handleReload = () => {
    dispatch(fetchMainchainHistory({ address, page: 0 }));
    setCurrentPage(0);
  };

  let content: ReactNode;
  let actionText: string;
  let action: () => void;

  if (confirmed) {
    content = (
      <div className={classes.content}>
        <Typography.Text>
          Your withdrawal is being confirmed on L1. Please allow a few minutes before the funds are credited to your L1
          wallet.
        </Typography.Text>
      </div>
    );
    actionText = "Ok";
    action = () => {
      setConfirmed(false);
      setSelectedRecord(undefined);
      handleReload();
    };
  } else {
    content = (
      <div className={classes.content}>
        <Typography.Text style={{ marginBottom: 12 }}>
          By clicking “Confirm”, all of your pending L1 withdrawals for {_.get(selectedRecord, "assetSymbol")} will be
          confirmed in this single transaction. No need to click confirm for other L1 withdrawals for{" "}
          {_.get(selectedRecord, "assetSymbol")}.
        </Typography.Text>
        <Typography.Text>
          The confirmation is an L1 transaction that costs some gas fee and it may take a few minutes to receive the
          withdrawn assets in your L1 wallet.
        </Typography.Text>
      </div>
    );
    actionText = "Confirm";
    action = handleConfirm;
  }
  const mobileList = currentPage === 0 ? prependL1Txs(entries).map(mapDataToCols) : entries.map(mapDataToCols);
  return (
    <div className={classes.container}>
      {isMobile ? (
        <div className={classes.mainchainHistory}>
          <div className={classes.reloadbtn}>
            <ReloadButton onClick={handleReload} className={classes.refreshButton} />
          </div>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} spinning={loading}>
            <div className={classes.historyList}>
              {mobileList.map(item => {
                return (
                  <div className={classes.historyListItem} key={item.key}>
                    <div className={classes.ListItemLeft}>
                      <div className={classes.itemTop}>
                        <span className={classes.itemTopdesp}>
                          {(() => {
                            switch (item.action) {
                              case "DEPOSIT":
                                return "Deposit to L2";
                              case "WITHDRAW":
                                return "Withdraw to L1";
                              default:
                                return "Unknown";
                            }
                          })()}
                        </span>
                        <span className={classes.itemTopval}>
                          {(() => {
                            const sign = item.amount.action === "DEPOSIT" ? "" : "-";
                            return (
                              <TokenGain
                                formattedAmount={sign + item.amount.assetAmt}
                                symbol={item.amount.assetSymbol}
                              />
                            );
                          })()}
                        </span>
                      </div>
                      <div className={classes.itemBottom}>
                        {(() => {
                          if (!item.time || item.time === "0") {
                            return "--";
                          }
                          const epoch = _.toNumber(item.time);
                          return new Date(epoch).toLocaleString();
                        })()}
                      </div>
                    </div>
                    <div className={classes.ListItemRight}>{changeStatus(item)}</div>
                  </div>
                );
              })}
            </div>
            <div className={classes.pagination}>
              <PageFlipper
                page={currentPage}
                hasMore={hasMore}
                onPageChange={(toPage: number) => setCurrentPage(toPage)}
              />
            </div>
          </Spin>
        </div>
      ) : (
        <div>
          <ReloadButton onClick={handleReload} className={classes.refreshButton} />
          <Table
            dataSource={currentPage === 0 ? prependL1Txs(entries).map(mapDataToCols) : entries.map(mapDataToCols)}
            columns={columns}
            pagination={false}
            loading={{
              spinning: loading,
              indicator: <LoadingOutlined style={{ fontSize: 40 }} />,
            }}
            locale={{ emptyText: <div style={{ height: 200 }} /> }}
          />
          <div className={classes.pagination}>
            <PageFlipper
              page={currentPage}
              hasMore={hasMore}
              onPageChange={(toPage: number) => setCurrentPage(toPage)}
            />
          </div>
        </div>
      )}

      <ActionModal
        visible={!!selectedRecord}
        actionText={actionText}
        title={<ActionTitle title="Withdraw to L1" />}
        actionLoading={confirmLoading}
        errMsg={errMsg}
        onCancel={() => setSelectedRecord(undefined)}
        onAction={action}
      >
        {content}
      </ActionModal>
    </div>
  );
}
