import { Card, Col, Row } from "antd";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useLocalStorage } from "react-use";

import { InvestDetail, TokenList } from "../components";
import LabelWithPopover from "../components/LabelWithPopover";
import { ProviderModal } from "../components/modals";
import { formatUSD } from "../helpers/format";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchAssets, setSelectedToken, setSelectedTokenIndex } from "../redux/assetSlice";
import { fetchGlobalInfo } from "../redux/globalInfoSlice";
import { closeModal, ModalName } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { saveShow } from "../redux/tokenToggleSlice";
import { Theme } from "../theme";

const useStyles = createUseStyles((theme: Theme) => {
  return {
    tvlCard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.fontSizeM,
      fontWeight: "bold",
    },
    tvl: {
      marginLeft: 6,
      color: theme.win,
      fontSize: theme.fontSizeL * 1.2,
      fontWeight: 600,
    },
  };
});

export default function Invest(): JSX.Element {
  const classes = useStyles();
  const { address } = useWeb3Context();
  const dispatch = useAppDispatch();
  const { asset, modal } = useAppSelector(state => state);
  const { selectedIndex, loading: assetsLoading, assets, tvl } = asset;
  const { showProviderModal } = modal;
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const { IsShow } = useAppSelector(state => state.tokenToggle);
  const [lastSelectedToken, setLastSelectedToken] = useLocalStorage<string>("lastSelectedToken");

  useEffect(() => {
    dispatch(fetchAssets(address));
    dispatch(fetchGlobalInfo());
  }, [dispatch, address]);

  useEffect(() => {
    if (selectedIndex === 1000 && !assetsLoading && assets.length) {
      if (lastSelectedToken) {
        dispatch(setSelectedToken(lastSelectedToken));
      } else {
        dispatch(setSelectedTokenIndex(0));
      }
    }
  }, [dispatch, selectedIndex, lastSelectedToken, assetsLoading, assets]);

  const handleCloseProviderModal = () => {
    dispatch(closeModal(ModalName.provider));
  };

  const handleSelectToken = (id: number | string) => {
    dispatch(setSelectedToken(id + ""));
    setLastSelectedToken(id + "");
    dispatch(saveShow({ IsShow: false }));
  };

  return (
    <>
      <Card bodyStyle={{ padding: 12 }} style={{ marginBottom: 12 }}>
        <div className={classes.tvlCard}>
          <LabelWithPopover label="Total Value Locked:" iconOnLeft>
            This is the total value locked in the Layer2.Finance protocol, including the total value that has been
            committed to strategies and the total unallocated L2 balance locked in the rollup chain.
          </LabelWithPopover>
          {!assetsLoading ? (
            <span className={classes.tvl}>{formatUSD(tvl.toFixed(0) + "")}</span>
          ) : (
            <span className={classes.tvl}>$--</span>
          )}
        </div>
      </Card>

      {!isMobile ? (
        <div>
          <Row gutter={16}>
            <Col span={6}>
              <TokenList onSelectToken={handleSelectToken} />
            </Col>
            <Col span={18}>
              <InvestDetail />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <InvestDetail />
          <div style={IsShow ? { display: "block" } : { display: "none" }}>
            <TokenList onSelectToken={handleSelectToken} />
          </div>
        </div>
      )}
      <ProviderModal visible={showProviderModal} onCancel={handleCloseProviderModal} />
    </>
  );
}
