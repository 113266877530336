import { Input } from "antd";
import { ChangeEvent, FC } from "react";

export interface ITokenInputChangeEvent {
  value: string;
  error?: string;
}

interface IProps {
  value: string;
  onChange: (e: ITokenInputChangeEvent) => void;
}

const validFloatRegex = /([0-9]*[.])?[0-9]+/;

const TokenInput: FC<IProps> = props => {
  const { value, onChange } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    onChange({
      value: e.target.value,
      error: !validFloatRegex.test(val) ? "Please enter a valid number" : "",
    });
  };

  return (
    <Input
      autoFocus
      className="action-input"
      type="number"
      bordered={false}
      size="large"
      value={value}
      onChange={handleChange}
    />
  );
};

export default TokenInput;
