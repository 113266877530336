import { ethers } from "ethers";
import { createContext, ReactChild, ReactChildren, useContext, useEffect, useState } from "react";

import { transactorWithNotifier } from "../helpers";
import { Transactor } from "../helpers/transactorWithNotifier";
import { useContractLoader } from "../hooks";
import { L2FContracts, l2fContracts } from "../hooks/contractLoader";
import { useAppSelector } from "../redux/store";
import { useWeb3Context } from "./Web3ContextProvider";

interface ContractsContextProps {
  contracts: L2FContracts;
  transactor: Transactor<ethers.ContractTransaction> | undefined;
}

interface ContractsContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const ContractsContext = createContext<ContractsContextProps>({
  contracts: l2fContracts,
  transactor: undefined,
});

export const ContractsContextProvider = ({ children }: ContractsContextProviderProps): JSX.Element => {
  const { provider } = useWeb3Context();
  const [addresses, setAddresses] = useState<Record<string, string>>({});

  const { faucetAddress, rollupChainAddress } = useAppSelector(state => state.globalInfo);

  useEffect(() => {
    if (rollupChainAddress && faucetAddress) {
      setAddresses({
        rollupChain: rollupChainAddress,
        faucet: faucetAddress,
      });
    }
  }, [faucetAddress, rollupChainAddress]);

  const contracts = useContractLoader(provider, addresses);
  const transactor = transactorWithNotifier<ethers.ContractTransaction>(provider);

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        transactor,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContractsContext: () => ContractsContextProps = () => useContext(ContractsContext);
