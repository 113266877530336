import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "../redux/store";
import { saveWidth } from "../redux/windowWidthSlice";

export default function useWindowWidth() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
  });

  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);
  const dispatch = useAppDispatch();
  dispatch(saveWidth({ winWidth: size.width }));
  return size;
}
