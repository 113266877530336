import "antd/dist/antd.css";
import "./App.less";

import { Button, Layout, Menu } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { createUseStyles, ThemeProvider } from "react-jss";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import VConsole from "vconsole";

import { GithubFilled, TwitterCircleFilled } from "@ant-design/icons";

import { Header } from "../components";
import { useWindowWidth } from "../hooks";
import { DiscordCircleFilled, TelegramCircleFilled } from "../icons";
import mobileStyle from "../mobileStyle";
import { useAppSelector } from "../redux/store";
import defaultTheme, { Theme } from "../theme";
import { History, Invest } from "../views";

const { Content } = Layout;
if (process.env.REACT_APP_DEBUG) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const vConsole = new VConsole();
}

const useStyles = createUseStyles<string, { isMobile: boolean }, Theme>(() => ({
  layout: {
    maxWidth: props => (props.isMobile ? mobileStyle : defaultTheme).pcMaxWidth,
    minWidth: props => (props.isMobile ? mobileStyle : defaultTheme).pcMinWidth,
    background: props => (props.isMobile ? mobileStyle : defaultTheme).bgColorPrimary,
    minHeight: "calc(100vh - 32px)",
    marginBottom: 32,

    "@global": {
      ".ant-card": {
        background: props => (props.isMobile ? mobileStyle : defaultTheme).bgColorSecondary,
      },
    },
  },
  content: {
    margin: "0 10px",
  },
  footer: {
    margin: "40px 10px 0px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    "& p, button": {
      color: props => (props.isMobile ? mobileStyle : defaultTheme).fontColorTertiary,
      marginBottom: 5,
      fontSize: props => (props.isMobile ? mobileStyle : defaultTheme).fontSizeS,
    },
  },
  footerLink: {
    marginRight: -8,
    "& span": {
      textDecoration: "underline",
    },
  },
  footerRight: {
    marginLeft: "auto",
    color: props => (props.isMobile ? mobileStyle : defaultTheme).fontColorTertiary,
  },
  nav: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  menu: {
    borderRadius: "12px",
    margin: "18px 0",
    background: "none",
    border: "none",
    display: "flex",
    alignItems: "center",

    "& .ant-menu-item a": {
      color: "rgba(255, 255, 255, 0.4)",
      fontWeight: 400,
      fontSize: "24px",
      "&:hover": {
        color: props => (props.isMobile ? mobileStyle : defaultTheme).fontColorSecondary,
      },
    },

    "& .ant-menu-item-selected a": {
      color: "#ffffff",
    },
  },
  social: {
    marginLeft: "auto",
    fontSize: 24,
    "& span": {
      marginRight: 20,
    },
  },
  mobileApp: {
    padding: "0 16px",
  },
  mobilefoot: {
    marginBottom: 25,
    marginTop: 25,
    paddingBottom: 25,
  },
  footText: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "left",
    color: "#808080",
    "& span": {
      marginLeft: 10,
    },
  },
  menutext: {
    fontSize: 16,
    fontWeight: "bold",
  },
  mobileMinHeight: {
    minHeight: "calc(100vh - 230px)",
  },

  extra: {
    display: "flex",
    marginLeft: "auto",
  },
  notification: {
    display: "flex",
    alignItems: "center",
    background: "#FFF",
    color: "#0A0A0A",
    fontWeight: 400,
    borderRadius: 12,
    gap: 7,
    marginRight: 24,
  },

  bell: {
    width: 13,
    height: 15,
    marginLeft: 12,
  },

  notificationContent: {
    margin: "8px 12px 8px 0px",
  },
}));

function App(): JSX.Element {
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const classes = useStyles({ isMobile });
  const location = useLocation();
  useWindowWidth();
  const [route, setRoute] = useState("");
  useEffect(() => {
    const segments = location.pathname.split("/").filter(p => p);
    setRoute(segments[0]);
  }, [location]);

  return (
    <ThemeProvider theme={!isMobile ? defaultTheme : mobileStyle}>
      {!isMobile ? (
        <div className="App">
          <Layout className={classes.layout}>
            <Header />
            <Content className={classes.content}>
              <div className={classes.nav}>
                <Menu className={classes.menu} selectedKeys={[route]} mode="horizontal">
                  <Menu.Item key="invest" style={{ borderBottomStyle: "none" }}>
                    <Link to="/invest">Invest</Link>
                  </Menu.Item>
                  <Menu.Item key="history" style={{ borderBottomStyle: "none" }}>
                    <Link to="/history">History</Link>
                  </Menu.Item>
                </Menu>
                <div className={classes.extra}>
                  {/* notification */}

                  {!isMobile && (
                    <div className={classes.notification}>
                      <img className={classes.bell} src="./bell.svg" alt="notification" />
                      <div className={classes.notificationContent}>
                        If you want to use <b>zk-proof version</b> of Layer2.Finance, please visit{" "}
                        <a href="https://zk.layer2.finance/" target="_blank" rel="noreferrer">
                          {" "}
                          here.
                        </a>
                      </div>
                    </div>
                  )}
                  <div className={classes.social}>
                    <DiscordCircleFilled onClick={() => window.open("https://discord.gg/uGx4fjQ", "_blank")} />
                    <TelegramCircleFilled onClick={() => window.open("https://t.me/celernetwork", "_blank")} />
                    <TwitterCircleFilled onClick={() => window.open("https://twitter.com/CelerNetwork", "_blank")} />
                    <GithubFilled
                      onClick={() => window.open("https://github.com/celer-network/layer2-finance-contracts", "_blank")}
                    />
                  </div>
                </div>
              </div>
              <Switch>
                <Route path="/invest">
                  <Invest />
                </Route>
                <Route path="/history">
                  <History />
                </Route>
                <Redirect from="/" to="/invest" />
              </Switch>
            </Content>
            <Footer className={classes.footer}>
              <div>
                <p>Built with ❤️ by the Celer community.</p>
                <p>
                  © Copyright 2021 Faster Thunder Ltd.{" "}
                  <Button
                    size="small"
                    type="link"
                    className={classes.footerLink}
                    onClick={() => window.open("./terms-of-use.pdf")}
                  >
                    Terms
                  </Button>
                  <Button
                    size="small"
                    type="link"
                    className={classes.footerLink}
                    onClick={() => window.open("./privacy-policy.pdf")}
                  >
                    Privacy
                  </Button>
                </p>
              </div>
              <div className={classes.footerRight}>
                <p>Powered by Celer Network</p>
              </div>
            </Footer>
          </Layout>
        </div>
      ) : (
        <div className={classes.mobileApp}>
          <Header />

          <div className={classes.notification} style={{ marginRight: 0, marginTop: 14 }}>
            <img className={classes.bell} src="./bell.svg" alt="notification" />
            <div className={classes.notificationContent}>
              If you want to use <b>zk-proof version</b> of Layer2.Finance, please visit{" "}
              <a href="https://zk.layer2.finance/" target="_blank" rel="noreferrer">
                {" "}
                here.
              </a>
            </div>
          </div>
          <div className={classes.nav}>
            <Menu className={classes.menu} selectedKeys={[route]} mode="horizontal">
              <Menu.Item key="invest" style={{ borderBottomStyle: "none", padding: 0, margin: 0 }}>
                <Link to="/invest">
                  <span className={classes.menutext}>Invest</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="history" style={{ borderBottomStyle: "none" }}>
                <Link to="/history">
                  <span className={classes.menutext}>History</span>
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className={classes.mobileMinHeight}>
            <Switch>
              <Route path="/invest">
                <Invest />
              </Route>
              <Route path="/history">
                <History />
              </Route>
              <Redirect from="/" to="/invest" />
            </Switch>
          </div>
          <div className={classes.mobilefoot}>
            <div className={classes.footText}>Built with ❤️ by the Celer community.</div>
            <div className={classes.footText}>
              © Copyright 2021 Faster Thunder Ltd.{" "}
              <span onClick={() => window.open("./terms-of-use.pdf")} style={{ textDecoration: "underline" }}>
                Terms
              </span>
              <span onClick={() => window.open("./privacy-policy.pdf")} style={{ textDecoration: "underline" }}>
                Privacy
              </span>
            </div>
            <div className={classes.footText} style={{ marginTop: 16 }}>
              Powered by Celer Network
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
