import { FC } from "react";
import { useTheme } from "theming";

import { Theme } from "../theme";

interface IProps {
  formattedAmount?: string;
  symbol?: string;
}

const TokenGain: FC<IProps> = ({ formattedAmount: amount, symbol }) => {
  const theme: Theme = useTheme();
  if (!amount || parseFloat(amount) === 0) {
    return <span>--</span>;
  }

  let titleColor: string | undefined;
  let sign: string;
  let amountWithoutSign: string;

  if (amount.indexOf("-") !== -1) {
    titleColor = theme.lose;
    sign = "-";
    amountWithoutSign = amount.replace("-", "");
  } else {
    titleColor = theme.win;
    sign = "+";
    amountWithoutSign = amount;
  }

  return (
    <span style={{ color: titleColor }}>
      <span>{`${sign} ${amountWithoutSign}`}</span>
      {symbol ? <span> {symbol}</span> : null}
    </span>
  );
};

export default TokenGain;
