import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { components } from "../api/api";
import { getGlobalInfo } from "../api/globalInfo";

const globalInfoSlice = createSlice({
  name: "globalInfo",
  initialState: {
    faucetAddress: "",
    rollupChainAddress: "",
    nextReallocationTime: "0",
  },
  reducers: {
    save: (state, { payload }: PayloadAction<components["schemas"]["GlobalInfo"]>) => {
      const { contractAddresses, nextReallocationTime } = payload;
      state.faucetAddress = contractAddresses?.faucet || "";
      state.rollupChainAddress = contractAddresses?.rollupChain || "";
      state.nextReallocationTime = nextReallocationTime || "0";
    },
  },
});

export const { save } = globalInfoSlice.actions;

export const fetchGlobalInfo = createAsyncThunk("globalInfo/fetchGlobalInfo", async (_, thunkApi) => {
  const globalInfo = await getGlobalInfo();
  thunkApi.dispatch(save(globalInfo));
});

export default globalInfoSlice.reducer;
