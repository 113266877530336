import { Button, Dropdown, Menu, PageHeader, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { useToggle } from "react-use";

import { CloseOutlined, GithubFilled, MenuOutlined, TwitterCircleFilled } from "@ant-design/icons";

import { getNetworkById, NETWORKS } from "../constants/network";
import { DiscordCircleFilled, TelegramCircleFilled } from "../icons";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { useAppSelector } from "../redux/store";
import Account from "./Account";
import { FaucetModal } from "./modals";

const useStyles = createUseStyles(() => ({
  header: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  extra: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  link: {
    color: "#ffffff",
  },
  mobileheader: {},
  headlogo: {
    marginTop: 20,
  },
  headerTop: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  heardModal: {
    width: "100vw",
    height: "100%",
    background: "#000000",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  mobileextra: {
    marginTop: 100,
  },
  extraItem: {
    marginBottom: 24,
    textAlign: "center",
    "& span": {
      fontsize: "20px",
    },
  },
  followus: {
    marginTop: 20,
    textAlign: "center",
  },
  socialm: {
    textAlign: "center",
    fontSize: 24,
    "& span": {
      marginRight: 20,
    },
    marginTop: 18,
    display: "flex",
    justifyContent: "around",
  },
  closeIcon: {
    fontSize: 20,
    color: "#fff",
    position: "absolute",
    top: 30,
    right: 20,
  },
  mobilefoot: {
    position: "absolute",
    left: "6.52%",
    right: "6.52%",
    top: "80.36%",
    bottom: "18.08%",
  },
  footText: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "left",
    color: "#808080",
    "& span": {
      marginLeft: 10,
    },
  },
}));

export default function Header(): JSX.Element {
  const classes = useStyles();
  const { chainId } = useWeb3Context();
  const [showFaucet, toggleFaucet] = useToggle(false);
  const [menuModal, toggleMenu] = useToggle(false);
  const { assets } = useAppSelector(state => state.asset);
  const { isMobile } = useAppSelector(state => state.windowWidth);

  const tokenInfo = useMemo(() => {
    return assets
      .filter(token => token.symbol !== "ETH")
      .map(token => ({ symbol: token.symbol || "", address: token.address || "" }));
  }, [assets]);

  const expectedChainId = Number(process.env.REACT_APP_NETWORK_ID);

  if (chainId !== expectedChainId) {
    const expectedNetwork = getNetworkById(expectedChainId);
    const name = expectedNetwork.name;
    return (
      <Modal closable={false} visible width={360} footer={null}>
        <Typography.Text className="sub-typography">
          Please switch to {name.charAt(0).toUpperCase() + name.slice(1)}
        </Typography.Text>
      </Modal>
    );
  }

  return !isMobile ? (
    <div className={classes.header}>
      <PageHeader title={<img src="./logo.png" height="35px" alt="LAYER2.FINANCE" />} />
      <div className={classes.extra}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="en_US" onClick={() => window.open("https://docs.l2.finance/#/tutorial-en", "_blank")}>
                English
              </Menu.Item>
              <Menu.Item key="zh_CN" onClick={() => window.open("https://docs.l2.finance/#/tutorial-cn", "_blank")}>
                中文
              </Menu.Item>
            </Menu>
          }
        >
          <Button className={classes.link} type="text" size="large">
            Guide
          </Button>
        </Dropdown>
        <Button
          className={classes.link}
          type="text"
          size="large"
          onClick={() => window.open("https://docs.l2.finance/#/faq", "_blank")}
        >
          FAQ
        </Button>
        {expectedChainId !== NETWORKS.mainnet.chainId && (
          <Button
            className={classes.link}
            type="text"
            size="large"
            onClick={() => {
              window.open("https://layer2.finance/leaderboard", "_blank");
            }}
          >
            Leaderboard
          </Button>
        )}
        {expectedChainId !== NETWORKS.mainnet.chainId && (
          <Button className={classes.link} type="text" size="large" onClick={toggleFaucet}>
            Faucets
          </Button>
        )}
        {showFaucet && <FaucetModal tokenInfos={tokenInfo} onClose={toggleFaucet} />}
        <Account />
      </div>
    </div>
  ) : (
    <div className={classes.mobileheader}>
      <div className={classes.headerTop}>
        <div className={classes.headlogo}>
          <img src="./logo.png" alt="LAYER2.FINANCE" style={{ width: 150 }} />
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 14 }}>
          <Account />
          <MenuOutlined style={{ fontSize: "20px", marginLeft: 10 }} onClick={toggleMenu} />
        </div>
      </div>
      {menuModal && (
        <div className={classes.heardModal}>
          <CloseOutlined className={classes.closeIcon} onClick={toggleMenu} />
          <div className={classes.mobileextra}>
            <div className={classes.extraItem}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="en_US"
                      onClick={() => window.open("https://docs.l2.finance/#/tutorial-en", "_blank")}
                    >
                      English
                    </Menu.Item>
                    <Menu.Item
                      key="zh_CN"
                      onClick={() => window.open("https://docs.l2.finance/#/tutorial-cn", "_blank")}
                    >
                      中文
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className={classes.link} type="text" size="large">
                  Guide
                </Button>
              </Dropdown>
            </div>
            <div className={classes.extraItem}>
              <Button
                className={classes.link}
                type="text"
                size="large"
                onClick={() => window.open("https://docs.l2.finance/#/faq", "_blank")}
              >
                FAQ
              </Button>
            </div>
            <div className={classes.followus}>
              <Button className={classes.link} type="text" size="large">
                Follow Us
              </Button>
              <div className={classes.socialm}>
                <DiscordCircleFilled onClick={() => window.open("https://discord.gg/uGx4fjQ", "_blank")} />
                <TelegramCircleFilled onClick={() => window.open("https://t.me/celernetwork", "_blank")} />
                <TwitterCircleFilled onClick={() => window.open("https://twitter.com/CelerNetwork", "_blank")} />
                <GithubFilled
                  style={{ marginRight: 0 }}
                  onClick={() => window.open("https://github.com/celer-network/layer2-finance-contracts", "_blank")}
                />
              </div>
            </div>
          </div>
          <div className={classes.mobilefoot}>
            <div className={classes.footText}>Built with ❤️ by the Celer community.</div>
            <div className={classes.footText}>
              © Copyright 2021 Faster Thunder Ltd.{" "}
              <span onClick={() => window.open("./terms-of-use.pdf")} style={{ textDecoration: "underline" }}>
                Terms
              </span>
              <span onClick={() => window.open("./privacy-policy.pdf")} style={{ textDecoration: "underline" }}>
                Privacy
              </span>
            </div>
            <div className={classes.footText} style={{ marginTop: 16 }}>
              Powered by Celer Network
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
