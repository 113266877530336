import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { components } from "../api/api";
import { getInvestmentHistory } from "../api/history";

interface IInvestmentHistorySlice {
  entries: components["schemas"]["InvestmentHistoryEntry"][];
  hasMore: boolean;
  loading: boolean;
}

const initialState: IInvestmentHistorySlice = {
  entries: [],
  hasMore: true,
  loading: false,
};

const historySlice = createSlice({
  name: "investmentHistory",
  initialState,
  reducers: {
    save: (
      state,
      { payload }: PayloadAction<{ entries: components["schemas"]["InvestmentHistoryEntry"][]; hasMore: boolean }>,
    ) => {
      state.entries = payload.entries;
      state.hasMore = payload.hasMore;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const fetchInvestmentHistory = createAsyncThunk(
  "investmentHistory/fetchInvestmentHistory",
  async ({ address, page }: { address: string; page: number }, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    const res = await getInvestmentHistory(address, page);
    thunkApi.dispatch(save({ entries: res.entries, hasMore: res.more || false }));
    thunkApi.dispatch(setLoading(false));
  },
);

export const { save, setLoading } = historySlice.actions;

export default historySlice.reducer;
