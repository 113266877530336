import { useEffect, useState } from "react";

import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { Contract, ContractFactory } from "@ethersproject/contracts";
import { InfuraProvider, JsonRpcProvider } from "@ethersproject/providers";

import { Faucet__factory } from "../typechain/factories/Faucet__factory";
import { RollupChain__factory } from "../typechain/factories/RollupChain__factory";
import { Faucet } from "../typechain/Faucet";
import { RollupChain } from "../typechain/RollupChain";

export type L2FContracts = {
  rollupChain: RollupChain | undefined;
  faucet: Faucet | undefined;
};

export type L2FContractFactoryClasses = {
  [key: string]: { new (signer: Signer): ContractFactory };
};

export const l2fContractFactories: L2FContractFactoryClasses = {
  rollupChain: RollupChain__factory,
  faucet: Faucet__factory,
};

export const l2fContracts: L2FContracts = {
  rollupChain: undefined,
  faucet: undefined,
};

function loadContract(keyName: string, signer: Signer, addresses: Record<string, string>): Contract | undefined {
  if (!(keyName in addresses)) {
    return undefined;
  }
  const newContract = new l2fContractFactories[keyName](signer).attach(addresses[keyName]);
  return newContract;
}

/**
 * Converts a Signer or Provider to a Signer.
 *
 * @param signerOrProvider A Signer or a Provider.
 * @returns A Signer.
 */
export async function ensureSigner(signerOrProvider: Signer | Provider): Promise<Signer | undefined> {
  let signer: Signer;
  let accounts: string[] = [];
  if (signerOrProvider && typeof (signerOrProvider as JsonRpcProvider).listAccounts === "function") {
    accounts = await (signerOrProvider as JsonRpcProvider).listAccounts();
  }

  if (accounts && accounts.length > 0) {
    signer = (signerOrProvider as JsonRpcProvider).getSigner();
  } else if (signerOrProvider instanceof InfuraProvider) {
    return undefined;
  } else {
    signer = signerOrProvider as Signer;
  }
  return signer;
}

/**
 * Loads pre-defined L2F contracts.
 *
 * @param signerOrProvider A Signer or a Provider.
 * @param addresses The contract address.
 * @returns The contracts.
 */
export default function useContractLoader(
  signerOrProvider: Signer | Provider | undefined,
  addresses: Record<string, string>,
): L2FContracts {
  const [contracts, setContracts] = useState<L2FContracts>(l2fContracts);
  useEffect(() => {
    async function loadContracts() {
      if (typeof signerOrProvider !== "undefined") {
        try {
          const signer = await ensureSigner(signerOrProvider);
          if (!signer) {
            return;
          }
          const newContracts = Object.keys(l2fContracts).reduce((accumulator, keyName) => {
            accumulator[keyName] = loadContract(keyName, signer, addresses);
            return accumulator;
          }, {}) as L2FContracts;
          setContracts(newContracts);
        } catch (e) {
          console.log("Error loading contracts", e);
        }
      }
    }
    loadContracts();
  }, [signerOrProvider, addresses]);
  return contracts;
}
