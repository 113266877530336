import { Avatar, Button, Modal, Space, Spin, Typography } from "antd";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { injected } from "web3modal";

import { RightOutlined } from "@ant-design/icons";

import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useAppSelector } from "../../redux/store";
import ActionTitle from "./ActionTitle";

const { Text } = Typography;

const useStyles = createUseStyles({
  provider: {
    border: "0.5px solid #C4C4C4",
    borderRadius: 12,
    width: "100%",
    height: 60,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    padding: 14,

    "@global": {
      ".ant-spin": {
        marginTop: 6,
      },
    },
  },
  providerLoading: {
    pointerEvents: "none",
    background: "rgba(255,255,255,0.1)",
    border: "0.5px solid #a4a4a4",
  },
  rightText: {
    fontSize: 11,
    display: "inline-block",
    marginRight: 6,
    color: "rgba(255, 255, 255, 0.65)",
  },
  arrow: {
    marginRight: 15,
    color: "#A0A0A0",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
  },
});

interface ProviderModalProps {
  visible: boolean;
  onCancel: () => void;
}

function RightContent({ loading }) {
  const classes = useStyles();
  return loading ? (
    <span className={classes.rightText}>Continue in the Metamask popup</span>
  ) : (
    <RightOutlined className={classes.arrow} />
  );
}

function Provider({ provider, onClick }) {
  const classes = useStyles();
  const { isMobile } = useAppSelector(state => state.windowWidth);
  return (
    <>
      {provider === "WalletConnect" ? (
        <div className={classNames(classes.provider, false && classes.providerLoading)} onClick={onClick}>
          <Space>
            {/* <Avatar src="./connect.png" shape={isMobile ? "circle" : "square"} /> */}
            <img src="./connect.png" alt="" style={{ width: 32 }} />
            <Text>WalletConnect</Text>
          </Space>
          <RightContent loading={false} />
        </div>
      ) : (
        <div className={classNames(classes.provider, false && classes.providerLoading)} onClick={onClick}>
          <Space>
            <Avatar src={provider.logo} shape={isMobile ? "circle" : "square"} />
            <Text>{provider.name}</Text>
          </Space>
          <RightContent loading={false} />
        </div>
      )}
    </>
  );
}

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Text>By connecting, I accept Layer2.Finance</Text>
      <Text>
        <Button type="link" size="small" onClick={() => window.open("./terms-of-use.pdf")}>
          Terms of Use
        </Button>{" "}
        |
        <Button type="link" size="small" onClick={() => window.open("./privacy-policy.pdf")}>
          Privacy Policy
        </Button>
      </Text>
    </div>
  );
}

export default function ProviderModal({ visible, onCancel }: ProviderModalProps): JSX.Element {
  const { loadWeb3Modal, connecting } = useWeb3Context();
  const { isMobile } = useAppSelector(state => state.windowWidth);
  const handleSelectProvider = async () => {
    await loadWeb3Modal("injected");
    onCancel();
  };
  // const handleWalletConnectProvider = async () => {
  //   await loadWeb3Modal("walletconnect");
  //   onCancel();
  // };

  return (
    <Modal
      closable
      visible={visible}
      width={isMobile ? "100vw" : 500}
      className="connectModal"
      title={<ActionTitle title="Connect Your Wallet" token={undefined} />}
      onCancel={onCancel}
      footer={<Footer />}
    >
      <div style={{ width: "100%" }}>
        <Spin spinning={connecting}>
          <Provider provider={injected.METAMASK} onClick={handleSelectProvider} />
          {/* {!isMobile && <Provider provider="WalletConnect" onClick={handleWalletConnectProvider} />} */}
          {isMobile && (
            <>
              <Provider provider={injected.IMTOKEN} onClick={handleSelectProvider} />
              <Provider provider={injected.COINBASE} onClick={handleSelectProvider} />
              <Provider provider={injected.TRUST} onClick={handleSelectProvider} />
              <Provider provider={injected.STATUS} onClick={handleSelectProvider} />
              <Provider provider={injected.FALLBACK} onClick={handleSelectProvider} />
            </>
          )}

          <Text>*Beta software. Use at your own risk!</Text>
        </Spin>
      </div>
    </Modal>
  );
}
