import { Spin } from "antd";
import _ from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

import { LoadingOutlined } from "@ant-design/icons";

import { components } from "../api/api";
import { formatBalance } from "../helpers/format";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchInvestmentHistory } from "../redux/investmentHistorySlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Theme } from "../theme";
import CustomTable from "./CustomTable";
import LabelWithPopover from "./LabelWithPopover";
import PageFlipper from "./PageFlipper";
import ReloadButton from "./ReloadButton";
import TokenGain from "./TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    margin: theme.hismainMargin,
    "@global": {
      "tbody .ant-table-cell": {
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
  },
  refreshButton: {
    position: "absolute",
    right: 10,
    top: 20,
  },
  mainText: {
    fontSize: theme.fontSizeL,
  },
  subText: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeS,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainchainHistory: {
    position: "relative",
  },
  reloadbtn: {
    position: "fixed",
    top: 47,
    right: 0,
    zIndex: 3,
  },
  historyList: {},
  historyListItem: {
    // height: 80,
    borderBottom: "1px solid #808080",
  },
  itemOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  ListItemLeft: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ListItemRight: {
    textAlign: "right",
    fontSize: "16px",
    color: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemTop: {
    // lineHeight: 1,
  },
  itemTopname: {
    fontSize: "14px",
  },
  itemTopdesp: {
    fontSize: "14px",
    color: "#fff",
    lineHeight: 1,
  },
  itemTopval: {
    fontSize: "14px",
    color: "#00D395",
    marginLeft: 8,
    lineHeight: 1,
  },
  itemBottom: {
    fontSize: "12px",
    color: "#c4c4c4",
    marginTop: 4,
    lineHeight: 1,
  },
}));

export default function StrategyHistory(): JSX.Element {
  const classes = useStyles();
  const { address } = useWeb3Context();
  const [currentPage, setCurrentPage] = useState(0);
  const { entries, hasMore, loading } = useAppSelector(state => state.investmentHistory);
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(state => state.windowWidth);

  useEffect(() => {
    if (!address) {
      return;
    }
    dispatch(fetchInvestmentHistory({ address, page: currentPage }));
  }, [address, currentPage, dispatch]);

  const mapDataToCols = useCallback((entry: components["schemas"]["InvestmentHistoryEntry"]) => {
    return {
      key: entry.tnId,
      title: entry.strategyName,
      strategyToken: {
        action: entry.action,
        amount: entry.stTokenAmt,
        symbol: entry.stTokenName,
      },
      assetToken: {
        action: entry.action,
        amount: entry.assetAmt,
        symbol: entry.assetSymbol,
      },
      status: {
        action: entry.action,
        status: entry.status,
        time: entry.commitTimestamp === "0" ? entry.acceptTimestamp : entry.commitTimestamp,
      },
    };
  }, []);
  const changeStatus = record => {
    const epoch = _.toNumber(record.status.time);
    let timeStr = "--";
    if (epoch !== 0) {
      timeStr = new Date(epoch).toLocaleString();
    }
    let statusText: string | ReactNode;
    switch (record.status.status) {
      case "PENDING":
        statusText =
          record.status.action === "FUND" ? (
            <LabelWithPopover label="Deposit Pending">
              This transaction is currently waiting for the next aggregated fund allocation execution, which may take up
              to 6 hours.
            </LabelWithPopover>
          ) : (
            <LabelWithPopover label="Withdraw Pending">
              This transaction is currently waiting for the next aggregated fund allocation execution, which may take up
              to 6 hours.
            </LabelWithPopover>
          );
        break;
      case "CONFIRMED":
        statusText = "Confirmed";
        break;
      default:
        statusText = "Error";
    }
    return (
      <div>
        <div style={{ fontWeight: "normal" }}>{statusText}</div>
        <div className={classes.subText}>{timeStr}</div>
      </div>
    );
  };
  type Cols = ReturnType<typeof mapDataToCols>;

  const columns = useMemo(
    () => [
      {
        title: "Strategy",
        dataIndex: "title",
        render: (title: string) => {
          return <span className={classes.mainText}>{title}</span>;
        },
      },
      {
        title: (
          <LabelWithPopover label="Transaction (Strategy Token)">
            Strategy Token (stToken) is your yield-bearing liquidity certificate that can later be used to redeem the
            underlying liquidity along with yields back to your available L2 balance.
          </LabelWithPopover>
        ),
        dataIndex: "strategyToken",
        render: ({ amount, symbol, action }: Cols["strategyToken"]) => {
          const sign = action === "FUND" ? "" : "-";
          const formattedAmt = formatBalance(sign + amount);
          return (
            <div>
              <div>
                <TokenGain formattedAmount={formattedAmt} />
              </div>
              <div className={classes.subText}>{symbol}</div>
            </div>
          );
        },
      },
      {
        title: (
          <LabelWithPopover label="Transaction (Asset Token)">
            Asset Token is the original token you have in your wallet asset such as DAI, CELR, WETH.
          </LabelWithPopover>
        ),
        dataIndex: "assetToken",
        render: ({ amount, symbol, action }: Cols["assetToken"]) => {
          const sign = action === "FUND" ? "-" : "";
          const formattedAmt = formatBalance(sign + amount);
          return (
            <div>
              <div>
                <TokenGain formattedAmount={formattedAmt} />
              </div>
              <div className={classes.subText}>{symbol}</div>
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (e, record) => changeStatus(record),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes],
  );

  const handleReload = () => {
    dispatch(fetchInvestmentHistory({ address, page: 0 }));
    setCurrentPage(0);
  };
  const mobileList = entries.map(mapDataToCols);

  return (
    <div className={classes.container}>
      {isMobile ? (
        <div className={classes.mainchainHistory}>
          <div className={classes.reloadbtn}>
            <ReloadButton onClick={handleReload} className={classes.refreshButton} />
          </div>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} spinning={loading}>
            <div className={classes.historyList}>
              {mobileList.map(item => {
                return (
                  <div className={classes.historyListItem} key={item.key}>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopname}>{item.title}</span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>{changeStatus(item)}</div>
                    </div>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopdesp}>
                            <LabelWithPopover label="Strategy Token">
                              Strategy Token (stToken) is your yield-bearing liquidity certificate that can later be
                              used to redeem the underlying liquidity along with yields back to your available L2
                              balance.
                            </LabelWithPopover>
                          </span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        {(() => {
                          const sign = item.strategyToken.action === "FUND" ? "" : "-";
                          const formattedAmt = formatBalance(sign + item.strategyToken.amount);
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 12,
                                justifyContent: "flex-end",
                              }}
                            >
                              <div>
                                <TokenGain formattedAmount={formattedAmt} />
                              </div>
                              <div className={classes.subText} style={{ marginLeft: 4 }}>
                                {item.strategyToken.symbol}
                              </div>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                    <div className={classes.itemOne} style={{ marginBottom: 25 }}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopdesp}>
                            <LabelWithPopover label="Asset Token">
                              Asset Token is the original token you have in your wallet asset such as DAI, CELR, WETH.
                            </LabelWithPopover>
                          </span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        {(() => {
                          const sign = item.assetToken.action === "FUND" ? "-" : "";
                          const formattedAmt = formatBalance(sign + item.assetToken.amount);
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 12,
                                justifyContent: "flex-end",
                              }}
                            >
                              <div>
                                <TokenGain formattedAmount={formattedAmt} />
                              </div>
                              <div className={classes.subText} style={{ marginLeft: 4 }}>
                                {item.assetToken.symbol}
                              </div>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {currentPage !== undefined ? (
              <div className={classes.pagination}>
                <PageFlipper
                  page={currentPage}
                  hasMore={hasMore}
                  onPageChange={(toPage: number) => setCurrentPage(toPage)}
                />
              </div>
            ) : null}
          </Spin>
        </div>
      ) : (
        <div>
          <ReloadButton onClick={handleReload} className={classes.refreshButton} />
          <CustomTable
            dataSource={entries.map(mapDataToCols)}
            columns={columns}
            loading={loading}
            currentPage={currentPage}
            hasMore={hasMore}
            onPageChange={(toPage: number) => setCurrentPage(toPage)}
          />
        </div>
      )}
    </div>
  );
}
