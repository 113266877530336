const mobileStyle = {
  successColor: "#68DE31",
  win: "#09FF97",
  lose: "#ff4d4f",

  fontColorPrimary: "#ffffff",
  fontColorSecondary: "#C4C4C4",
  fontColorTertiary: "#808080",

  inverseFontColorPrimary: "#000000",
  inverseFontColorSecondary: "#808080",

  fontSizeS: 12,
  fontSizeM: 14,
  fontSizeL: 16,
  fontSizeXL: 18,
  countfontSize: 12,

  bgColorPrimary: "#000000",
  bgColorSecondary: "rgba(255, 255, 255, 0.1)",
  bgColorTertiary: "#3A3A3A",

  borderColorPrimary: "#808080",

  highlightedText: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  pcMaxWidth: 576,
  pcMinWidth: 320,
  xlFlex: "flex",
  xsFlex: "unset",
  leftMargin: 0,
  balanceUnit: {
    height: 50,
    borderTop: "1px solid #808080",
    lineHeight: "50px",
    marginRight: 0,
  },
  countmarginLeft: "0px",
  apypadding: "6px 8px",
  actflexDirection: "unset",
  invlexDirection: "space-between",
  actalignItems: "center",
  modalbackground: "#2f2e2e",
  contentBackground: "#1a1a1a",
  hismainMargin: "0",
  mobilebtn: "49%",
  apyLine: 1,
  modalBottom: "5px",
};
export default mobileStyle;
