import { Button, Dropdown, Menu, Typography } from "antd";
import { useCallback } from "react";
import { createUseStyles } from "react-jss";

import { getNetworkById } from "../constants/network";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { ModalName, openModal } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";

const useStyles = createUseStyles({
  addressBtn: {
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(20px)",
    border: "none",
    borderRadius: 24,
    "& .ant-typography": {
      width: 120,
    },
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 24,
    verticalAlign: "middle",
    marginLeft: 8,
  },
});

export default function Account(): JSX.Element {
  const classes = useStyles();
  const { address, signer, chainId, logoutOfWeb3Modal } = useWeb3Context();
  const { isMobile } = useAppSelector(state => state.windowWidth);

  const dispatch = useAppDispatch();

  const showProviderModal = useCallback(() => {
    dispatch(openModal(ModalName.provider));
  }, [dispatch]);

  if (signer) {
    const menu = (
      <Menu>
        <Menu.Item onClick={logoutOfWeb3Modal}>Logout</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        {isMobile ? (
          <Button className={classes.addressBtn} type="ghost">
            <Typography.Text style={{ fontSize: 12, width: 85 }}>
              {`${address.slice(0, 6)}...${address.slice(address.length - 4, address.length)}`}
            </Typography.Text>
            <span className={classes.indicator} style={{ background: getNetworkById(chainId).color }} />
          </Button>
        ) : (
          <Button className={classes.addressBtn} type="ghost">
            <Typography.Text ellipsis={{ suffix: address.slice(-4) }}>{address}</Typography.Text>
            <span className={classes.indicator} style={{ background: getNetworkById(chainId).color }} />
          </Button>
        )}
      </Dropdown>
    );
  }

  return (
    <>
      {!isMobile && (
        <Button type="primary" onClick={showProviderModal}>
          Connect Wallet
        </Button>
      )}
    </>
  );
}
