import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatUnits } from "ethers/lib/utils";
import { components } from "../api/api";
import { getAssets } from "../api/asset";

interface ISliceState {
  assets: components["schemas"]["Asset"][];
  selectedIndex: number;
  loading: boolean;

  // derived states
  tvl: number; // string encoded BigNumber
}

const initialState: ISliceState = {
  assets: [],
  selectedIndex: 1000,
  loading: false,
  tvl: 0,
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    save: (state, { payload }: PayloadAction<components["schemas"]["Asset"][]>) => {
      const assets = payload.map(asset => {
        if (asset.symbol === "WETH") {
          asset.symbol = "ETH";
          asset.name = "ETH";
        }
        return asset;
      });
      state.assets = assets;
      state.tvl = assets.reduce((total, asset) => {
        const subtotal = total + parseFloat(formatUnits(asset.tvl || 0, asset.decimal)) * (asset.price || 0);
        return subtotal;
      }, 0);
    },
    setSelectedToken: (state, { payload }: PayloadAction<string | undefined>) => {
      const index = state.assets.findIndex(token => {
        return ((token.id as unknown) as string) === payload;
      });
      if (index !== -1) {
        state.selectedIndex = index;
      }
    },
    setSelectedTokenIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedIndex = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const { save, setSelectedToken, setLoading, setSelectedTokenIndex } = assetSlice.actions;

export const fetchAssets = createAsyncThunk("asset/fetchAssets", async (address: string, thunkApi) => {
  thunkApi.dispatch(setLoading(true));
  const assets = await getAssets(address).catch(_ => null);
  if (assets) {
    thunkApi.dispatch(save(assets));
  }
  thunkApi.dispatch(setLoading(false));
});

export default assetSlice.reducer;
