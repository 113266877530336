import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";

import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { useAppSelector } from "../redux/store";
import { Theme } from "../theme";

interface IProps {
  label?: string | ReactNode;
  subtitle?: string;
  padding?: number; // amount of paading in popover
  placement?: TooltipPlacement;
  className?: string;
  iconOnLeft?: boolean;
  centerLabel?: boolean;
  isContainLabel?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    zIndex: 10000,
    "@global": {
      ".ant-popover-inner": {
        borderRadius: 8,
      },
      ".ant-popover-arrow": {
        background: "white",
        border: "none",
      },
    },
  },
  label: {
    textAlign: "left",
    display: "inline",
  },
  subtitle: {
    color: theme.fontColorSecondary,
  },
  popoverContent: {
    margin: 0,
    maxWidth: 300,
    color: theme.inverseFontColorPrimary,
  },
  iconWrapper: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
  },
  labelInfoIcon: {
    margin: "0 6px",
    color: theme.fontColorSecondary,
    "&:hover": {
      color: theme.fontColorPrimary,
    },
  },
  offCenterIcon: {
    position: "absolute",
    top: -12,
  },
  labeltxt: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const LabelWithPopover: FC<IProps> = props => {
  const {
    label,
    children,
    subtitle,
    padding,
    placement = "top",
    className,
    iconOnLeft = false,
    centerLabel = false,
    isContainLabel = true,
  } = props;
  const classes = useStyles();
  const { isMobile } = useAppSelector(state => state.windowWidth);

  return (
    <div className={classes.label}>
      {isContainLabel ? (
        <Popover
          arrowPointAtCenter
          color="white"
          placement={placement}
          overlayClassName={classNames(classes.overlay, className)}
          content={
            <div className={classes.popoverContent} style={{ padding: padding ?? "" }}>
              {children}
            </div>
          }
        >
          <div className={classes.labeltxt}>
            {!iconOnLeft && <span>{label}</span>}
            <div className={classes.iconWrapper}>
              {isMobile ? (
                <InfoCircleOutlined
                  className={classNames(classes.labelInfoIcon, centerLabel && classes.offCenterIcon)}
                />
              ) : (
                <QuestionCircleOutlined
                  className={classNames(classes.labelInfoIcon, centerLabel && classes.offCenterIcon)}
                />
              )}
            </div>
            {iconOnLeft && <span>{label}</span>}
          </div>
        </Popover>
      ) : (
        <>
          {!iconOnLeft && label}
          <Popover
            arrowPointAtCenter
            color="white"
            placement={placement}
            overlayClassName={classNames(classes.overlay, className)}
            content={
              <div className={classes.popoverContent} style={{ padding: padding ?? "" }}>
                {children}
              </div>
            }
          >
            <div className={classes.iconWrapper}>
              {isMobile ? (
                <InfoCircleOutlined
                  className={classNames(classes.labelInfoIcon, centerLabel && classes.offCenterIcon)}
                />
              ) : (
                <QuestionCircleOutlined
                  className={classNames(classes.labelInfoIcon, centerLabel && classes.offCenterIcon)}
                />
              )}
            </div>
          </Popover>
          {iconOnLeft && label}
        </>
      )}

      {subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
    </div>
  );
};

export default LabelWithPopover;
