import { ButtonType } from "antd/lib/button";
import _ from "lodash";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router";

import { Zero } from "@ethersproject/constants";
import { parseEther, parseUnits } from "@ethersproject/units";

import { checkDepositLimit } from "../../api/rollupChain";
import { formatDecimal, formatMula } from "../../helpers/format";
import { useEthBalance } from "../../hooks";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { addDepositTx } from "../../redux/persistedTxSlice";
import { useAppDispatch } from "../../redux/store";
import { ITokenInputChangeEvent } from "../TokenInput";
import ActionTitle from "./ActionTitle";
import ActionModal from "./common/ActionModal";
import ModalExtraInfoRow from "./common/ModalExtraInfoRow";
import ModalResult from "./common/ModalResult";
import ModalTokenInput from "./common/ModalTokenInput";
import { DWModalProps } from "./DepositModal";

export default function DepositETHModal({ token, onClose, minDeposit, onError = () => {} }: DWModalProps): JSX.Element {
  const {
    contracts: { rollupChain },
    transactor,
  } = useContractsContext();
  const { provider, address } = useWeb3Context();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string | undefined>("");
  const [resultMsg, setResultMsg] = useState("");
  const [ethBalance, balanceLoading] = useEthBalance(provider, address);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleAction = async () => {
    if (!transactor || !rollupChain || !token.address) {
      return;
    }
    if (!amount) {
      return;
    }
    const value = parseUnits(amount, token.decimal);
    if (value.isZero()) {
      return;
    }
    if (value.gt(ethBalance)) {
      setErrMsg("Insufficient L1 balance in your wallet");
      return;
    }
    if (minDeposit && value.lt(parseUnits(minDeposit, token.decimal))) {
      setErrMsg(`Please input a number larger than ${minDeposit}`);
      return;
    }
    try {
      // token.address is WETH address
      setLoading(true);
      const { isDepositLimitReached, limit, netDeposit } = await checkDepositLimit(rollupChain, token.address, value);
      if (isDepositLimitReached) {
        const msg = `Currently, the ${token.symbol} pool can only accept a maximum deposit of ${formatDecimal(
          limit.sub(netDeposit),
          token.decimal,
        )} ${token.symbol}. Please revise your deposit amount.`;
        onError(msg);
        onClose();
        return;
      }
      const depositTx = await transactor(rollupChain.depositETH(token.address, value, { value }));
      const now = new Date().getTime();
      const tx = {
        ownerAddress: address,
        tnId: now,
        hash: depositTx.hash,
        assetAmt: formatDecimal(value, token.decimal),
        assetSymbol: token.symbol,
        // HACK: locally generate history entry doesn't have any canonical timestamp, using local machine time instead
        executeTimestamp: now + "",
        acceptTimestamp: now + "",
      };
      dispatch(addDepositTx(tx));
      await depositTx.wait();
      setResultMsg("Deposit has been submitted");
    } catch (e) {
      setErrMsg(_.get(e, "error.message"));
    }
    setLoading(false);
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
    setErrMsg(e.error);
  };

  // Reserve some ETH for gas.
  let ethAmount = ethBalance.sub(parseEther("0.03"));
  if (ethAmount.lt(0)) {
    ethAmount = Zero;
  }

  let content;
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extraInfo: ReactNode[];
  if (resultMsg) {
    content = (
      <ModalResult
        title={resultMsg}
        description="Deposit will be credited to your L2 balance after 5 block confirmations (about 3-5 minutes). You can check the progress in the history page."
      />
    );
    action = () => history.push("/history/investment");
    actionText = "Check History";
    buttonType = "link";
    extraInfo = [<ModalExtraInfoRow left="Deposit Amount" right={`${formatMula(amount, "")} ${token.symbol}`} />];
  } else {
    content = (
      <ModalTokenInput
        description="You first need to transfer assets from your L1 wallet to L2 to start investing and enjoy the gas-free DeFi experience."
        amount={amount}
        maxAmount={formatDecimal(ethAmount, token.decimal)}
        maxLoading={balanceLoading}
        symbol={token.symbol}
        onChange={handleTokenInputChange}
        bottomDescription={minDeposit && `Minimal Deposit Amount: ${minDeposit} ${token.symbol}`}
      />
    );
    action = handleAction;
    actionText = "Deposit";
    buttonType = "primary";
    extraInfo = [];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Deposit to L2 ETH" token={token} />}
      actionText={actionText}
      actionLoading={loading}
      errMsg={errMsg}
      onCancel={onClose}
      onAction={action}
      actionDisabled={!!errMsg}
      buttonType={buttonType}
      extra={extraInfo}
    >
      {content}
    </ActionModal>
  );
}
