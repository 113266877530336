import * as React from "react";

import AntdIcon, { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

const DiscordCircleFilledSvg = {
  icon: {
    tag: "svg",
    attrs: {
      width: "48",
      height: "48",
      viewBox: "0 0 48 48",
      focusable: "false",
      fill: "currentColor",
      fillRule: "evenodd",
      clipRule: "evenodd",
    },
    children: [
      {
        tag: "path",
        attrs: {
          d:
            "M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM14.9051 10.4H33.8949C35.4962 10.4 36.8 11.688 36.8 13.284V38.4L33.7531 35.74L32.0384 34.172L30.2245 32.506L30.9755 35.096H14.9051C13.3038 35.096 12 33.808 12 32.212V13.284C12 11.688 13.3038 10.4 14.9051 10.4Z",
        },
      },
      {
        tag: "path",
        attrs: {
          d:
            "M28.4246 28.6839C28.4246 28.6839 27.9145 28.0819 27.4893 27.5499C29.3458 27.0319 30.0543 25.8839 30.0543 25.8839C29.4733 26.2619 28.9206 26.5279 28.4246 26.7099C27.7161 27.0039 27.0358 27.1999 26.3698 27.3119C25.0093 27.5639 23.7622 27.4939 22.6994 27.2979C21.8916 27.1439 21.1972 26.9199 20.6162 26.6959C20.2902 26.5699 19.9359 26.4159 19.5817 26.2199C19.5604 26.2059 19.5391 26.1954 19.5179 26.1849C19.4966 26.1744 19.4754 26.1639 19.4541 26.1499C19.4258 26.1359 19.4116 26.1219 19.3974 26.1079C19.1423 25.9679 19.0006 25.8699 19.0006 25.8699C19.0006 25.8699 19.6809 26.9899 21.4806 27.5219C21.0555 28.0539 20.5311 28.6839 20.5311 28.6839C17.3993 28.5859 16.2089 26.5559 16.2089 26.5559C16.2089 22.0479 18.2495 18.3939 18.2495 18.3939C20.2902 16.8819 22.2317 16.9239 22.2317 16.9239L22.3734 17.0919C19.8226 17.8199 18.6463 18.9259 18.6463 18.9259C18.6463 18.9259 18.9581 18.7579 19.4825 18.5199C20.9988 17.8619 22.2034 17.6799 22.6994 17.6379L22.7311 17.6326C22.8026 17.6206 22.8661 17.6099 22.9403 17.6099C23.8047 17.4979 24.7826 17.4699 25.8029 17.5819C27.1492 17.7359 28.5947 18.1279 30.0685 18.9259C30.0685 18.9259 28.949 17.8759 26.5398 17.1479L26.7382 16.9239C26.7382 16.9239 28.6797 16.8819 30.7204 18.3939C30.7204 18.3939 32.7611 22.0479 32.7611 26.5559C32.7611 26.5559 31.5565 28.5859 28.4246 28.6839ZM22.234 22.3999C21.4326 22.3999 20.7999 23.1207 20.7999 23.9999C20.7999 24.8792 21.4467 25.5999 22.234 25.5999C23.0354 25.5999 23.6681 24.8792 23.6681 23.9999C23.6822 23.1207 23.0354 22.3999 22.234 22.3999ZM25.9318 23.9999C25.9318 23.1207 26.5644 22.3999 27.3659 22.3999C28.1673 22.3999 28.7999 23.1207 28.7999 23.9999C28.7999 24.8792 28.1673 25.5999 27.3659 25.5999C26.5785 25.5999 25.9318 24.8792 25.9318 23.9999Z",
        },
      },
    ],
  },
  name: "discord-circle",
  theme: "filled" as const,
};

const DiscordCircleFilled = (props: AntdIconProps, ref: React.MutableRefObject<HTMLSpanElement>) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <AntdIcon {...props} ref={ref} icon={DiscordCircleFilledSvg} />
);

DiscordCircleFilled.displayName = "TelegramCircleFilled";
export default React.forwardRef<HTMLSpanElement, AntdIconProps>(
  DiscordCircleFilled as React.ForwardRefRenderFunction<HTMLSpanElement, AntdIconProps>,
);
