export default {
  DAI: {
    iconUrl: "./tokenIcons/DAI.png",
  },
  USDC: {
    iconUrl: "./tokenIcons/USDC.png",
  },
  USDT: {
    iconUrl: "./tokenIcons/USDT.png",
  },
  BUSD: {
    iconUrl: "./tokenIcons/BUSD.png",
  },
  ETH: {
    iconUrl: "./tokenIcons/ETH.png",
  },
};

export const USD_TOKENS = {
  BUSD: true,
  USDC: true,
  USDT: true,
  DAI: true,
};
