import { Button, Space, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";

import { BigNumber } from "@ethersproject/bignumber";

import { components } from "../api/api";
import { USD_TOKENS } from "../constants/tokens";
import { formatDecimal, formatPercentage } from "../helpers/format";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchAssets } from "../redux/assetSlice";
import { fetchGlobalInfo } from "../redux/globalInfoSlice";
import { ModalName, openModal } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { fetchStrategies } from "../redux/strategySlice";
import { Theme } from "../theme";
import ContentList from "./ContentList";
import CustomTable from "./CustomTable";
import IconTag from "./IconTag";
import LabelWithPopover from "./LabelWithPopover";
import { ActionModal, ExitModal, FundModal } from "./modals";
import ActionTitle from "./modals/ActionTitle";
import TokenGain from "./TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {},
  listHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
    justifyContent: "space-between",
    marginBottom: 16,
  },
  apy: {
    display: "inline-block",
    padding: theme.apypadding,
    background: "#000000",
    lineHeight: theme.apyLine,
    color: theme.win,
    borderRadius: 8,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    "@global": {
      ".ant-btn": {
        fontSize: theme.fontSizeS,
      },
    },
  },
  actionPending: {
    display: "flex",
    flexDirection: theme.actflexDirection,
    justifyContent: theme.invlexDirection,
    alignItems: theme.actalignItems,
    margin: "-12px 0",

    "@global": {
      ".ant-btn.ant-btn-link": {
        padding: 0,
        height: "auto",
      },
    },
  },
  listSubtitle: {
    display: "block",
    marginBottom: 1,
  },
  protocolHeader: {
    fontSize: theme.fontSizeS,
    color: theme.inverseFontColorSecondary,
    marginBottom: 4,
  },
  protocols: {
    display: "flex",
    flexWrap: "nowrap",
  },
  protocol: {
    marginRight: 12,
    color: theme.inverseFontColorSecondary,
  },
  table: {
    margin: "0 -14px",
  },
  highlight: theme.highlightedText,
  tableList: {
    // height: 330,
    padding: 16,
    position: "relative",
    background: theme.bgColorTertiary,
    borderRadius: "12px",
    marginTop: 20,
  },
  tableListItem: {
    height: 50,
    lineHeight: "50px",
    borderBottom: `1px solid ${theme.borderColorPrimary}`,
    display: "flex",
    justifyContent: "space-between",
  },
  tableListItemLeft: {},
  tableListItemRight: {},
  tableListItemBtn: {
    width: "100%",
    paddingTop: 10,
    "& button": {
      width: "49%",
    },
  },
  countDown: {
    marginLeft: theme.countmarginLeft,
    color: "#C4C4C4",
    fontSize: theme.countfontSize,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    // marginTop: 10,
  },
  divider: {
    margin: 0,
  },
  tag: {
    padding: "4px 8px",
    display: "inline",
    borderRadius: 11,
    background: "#333333",
    marginLeft: 12,
  },
  investHeader: {
    display: "flex",
    alignItems: "center",
  },
  tokenText: {
    fontSize: theme.fontSizeXL,
  },
}));

const getStringComparator = (path: string[]) => (a, b) => {
  let valA = a;
  path.forEach(el => {
    valA = valA[el];
  });
  let valB = b;
  path.forEach(el => {
    valB = valB[el];
  });
  if (valA < valB) return -1;
  if (valA > valB) return 1;
  return 0;
};

const curveTokenSwapTexts = {
  DAI: "USDC and USDT",
  USDC: "DAI and USDT",
  USDT: "DAI and USDC",
};

export default function StrategyList(): JSX.Element {
  const classes = useStyles();
  const { signer, address } = useWeb3Context();
  const dispatch = useAppDispatch();
  const [showFund, toggleFund] = useToggle(false);
  const [showExit, toggleExit] = useToggle(false);
  const [selectedStrategy, setSelectedStrategy] = useState<components["schemas"]["Strategy"]>({});
  const [showCurveWarning, setShowCurveWarning] = useState(false);
  const { asset, strategy, globalInfo } = useAppSelector(state => state);
  const { nextReallocationTime } = globalInfo;
  const { assets, selectedIndex } = asset;
  const { strategies, loading } = strategy;
  const token = assets[selectedIndex];

  const { isMobile } = useAppSelector(state => state.windowWidth);

  useEffect(() => {
    dispatch(fetchStrategies({ address, tokenId: token.id }));
  }, [address, token.id, dispatch]);

  useEffect(() => {
    const show = !!selectedStrategy.protocols?.filter(protocol => protocol.name === "Curve").length;
    setShowCurveWarning(show);
  }, [selectedStrategy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showFund]);

  const handleCountdownComplete = () => {
    dispatch(fetchAssets(address));
    dispatch(fetchGlobalInfo());
  };
  const handleOpenProviderModal = useCallback(() => {
    dispatch(openModal(ModalName.provider));
  }, [dispatch]);
  const InvestHeader = ({ onCountdownComplete }) => {
    const renderCountdown = useCallback(() => {
      if (!nextReallocationTime || nextReallocationTime === "0") {
        return null;
      }
      return (
        <span className={classes.countDown}>
          <LabelWithPopover
            label={<span style={{ fontWeight: "normal" }}> Next aggregated fund allocation in</span>}
            iconOnLeft
          >
            Layer2.finance aggregates multiple users&apos; DeFi&apos;s fund allocation intents on L2 Rollup and executes
            them in a single transaction on L1. This timer shows approximate when the next execution will happen.
          </LabelWithPopover>
          <div className={classes.tag}>
            <Countdown
              key={nextReallocationTime}
              date={_.toNumber(nextReallocationTime)}
              daysInHours
              onComplete={onCountdownComplete}
            />
          </div>
        </span>
      );
    }, [onCountdownComplete]);

    return (
      <div className={classes.investHeader}>
        {!isMobile ? (
          <Space>
            <Avatar src={token?.iconUrl} />
            <Typography.Text className={classes.tokenText}>{token?.name}</Typography.Text>
          </Space>
        ) : null}
        {renderCountdown()}
      </div>
    );
  };

  const mapDataToCols = useCallback(
    (strat: components["schemas"]["Strategy"]) => {
      return {
        key: strat.id,
        title: {
          name: strat.name,
          protocols: strat.protocols,
        },
        tvl: formatDecimal(strat.tvl, token.decimal, token.symbol && USD_TOKENS[token.symbol] ? 0 : 2),
        apy: strat.apy,
        balance: {
          tokenAmt: strat.estimatedValue,
          tokenName: token.symbol,
        },
        earning: {
          tokenAmt: strat.earnings,
          tokenName: token.symbol,
        },
        actions: strat,
      };
    },
    [token],
  );
  const renderActions = useCallback(
    (e, strat) => {
      if (address !== "") {
        if (strat.actions.available) {
          return (
            <div className={classes.actions}>
              <Button
                type="primary"
                disabled={!signer}
                onClick={() => {
                  setSelectedStrategy(strat.actions);
                  toggleFund();
                }}
              >
                Deposit
              </Button>
              <Button
                type={isMobile ? "primary" : "text"}
                style={{ marginLeft: 4 }}
                disabled={!signer || BigNumber.from(strat.actions.stTokenAmt || 0).lte(0)}
                onClick={() => {
                  toggleExit();
                  setSelectedStrategy(strat.actions);
                }}
              >
                Withdraw
              </Button>
            </div>
          );
        }
        // Not available
        return (
          <div className={classes.actionPending}>
            <div style={{ marginLeft: -6 }}>
              <LabelWithPopover label="Aggregation Pending" iconOnLeft placement="left">
                Your fund allocation intent is confirmed on L2 and Layer2.Finance is waiting for the next aggregated
                fund allocation execution.
              </LabelWithPopover>
            </div>
            <div>
              <Button type="link" key="button1">
                <Link to="/history/investment">Check History</Link>
              </Button>
            </div>
          </div>
        );
      }
      // Not connected
      return (
        <div className={classes.actions}>
          <Button type="primary" onClick={handleOpenProviderModal}>
            Connect Wallet
          </Button>
        </div>
      );
    },
    [address, classes, handleOpenProviderModal, signer, toggleExit, toggleFund, isMobile],
  );

  const columns = useMemo(
    () => [
      {
        title: "Strategies",
        dataIndex: "title",
        render: (e, record) => {
          return (
            <LabelWithPopover label={record.title.name} placement="topLeft">
              <div className={classes.protocolHeader}>Defi Protocols Used:</div>
              <div className={classes.protocols}>
                {record.title.protocols?.map(protocol => (
                  <IconTag key={protocol.name} className={classes.protocol} iconUrl={protocol.iconUrl || ""}>
                    {protocol.name}
                  </IconTag>
                ))}
              </div>
            </LabelWithPopover>
          );
        },
        sorter: getStringComparator(["title", "name"]),
      },
      {
        title: "TVL",
        dataIndex: "tvl",
        filterIcon: "",
        render: (e, record) => {
          return `${record.tvl} ${token.symbol}`;
        },
        sorter: getStringComparator(["tvl"]),
      },
      {
        title: "APY",
        dataIndex: "apy",
        render: (e, record) => {
          return <span className={classes.apy}>{formatPercentage(record.apy, false)}</span>;
        },
        sorter: getStringComparator(["apy"]),
      },
      {
        title: (
          <div style={{ marginLeft: -6 }}>
            <LabelWithPopover label="Balance" iconOnLeft>
              This is your estimated asset value in each strategy. Note that the value is estimated based on your
              current stToken balance/price and may not be accurate.
            </LabelWithPopover>
          </div>
        ),
        dataIndex: "balance",
        render: (e, record) => {
          const balance = BigNumber.from(record.balance.tokenAmt || "0");
          if (balance.eq(0)) {
            return "--";
          }
          return (
            <span>
              {formatDecimal(balance, token.decimal, 6)} {record.balance.tokenName}
            </span>
          );
        },
        sorter: getStringComparator(["balance", "tokenAmt"]),
      },
      {
        title: (
          <div style={{ marginLeft: -6 }}>
            <LabelWithPopover label="Earning" iconOnLeft>
              This is your yields harvested from each strategy. Earning is updated every 24 hours.
            </LabelWithPopover>
          </div>
        ),
        dataIndex: "earning",
        render: (e, record) => {
          let amount = BigNumber.from(record.earning.tokenAmt || "0");

          // avoid backend earning calculation rounding issue aka "-1" earning
          if (amount.gte(-5) && amount.lt(0)) {
            amount = BigNumber.from(0);
          }
          return <TokenGain formattedAmount={formatDecimal(amount, token.decimal)} symbol={record.earning.tokenName} />;
        },
        sorter: getStringComparator(["earning", "tokenAmt"]),
      },
      {
        title: "",
        dataIndex: "actions",
        render: (e, strat) => renderActions("", strat),
      },
    ],
    [classes, token.symbol, token.decimal, renderActions],
  );
  return (
    <div className={classes.container}>
      <div className={classes.listHeader} style={isMobile ? { marginBottom: 0 } : { marginBottom: 16 }}>
        <Typography.Title level={5}>Investment Strategies</Typography.Title>
      </div>
      {!isMobile ? (
        <CustomTable
          columns={columns}
          dataSource={loading ? [] : strategies.map(mapDataToCols)}
          loading={loading}
          className={classes.table}
        />
      ) : (
        <>
          <InvestHeader onCountdownComplete={handleCountdownComplete} />
          <ContentList dataList={strategies.map(mapDataToCols)} columns={columns} />
        </>
      )}
      {showFund && showCurveWarning && (
        <ActionModal
          title={<ActionTitle title={`Deposit to ${selectedStrategy.name}`} />}
          onAction={() => setShowCurveWarning(false)}
          onCancel={() => toggleFund()}
          visible
        >
          <p>
            <Typography.Text>
              This strategy involves swapping your token to {curveTokenSwapTexts[token.symbol || ""]} in Curve 3Pool and
              provide liquidity and yield mining $CRV token.
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Due to inherent slippage and fluctuation of the underlying stable token price,{" "}
              <span className={classes.highlight}>your earning might be negative</span> for a certain period of time.
            </Typography.Text>
          </p>
        </ActionModal>
      )}
      {showFund && !showCurveWarning && <FundModal token={token} strategy={selectedStrategy} onClose={toggleFund} />}
      {showExit && <ExitModal token={token} strategy={selectedStrategy} onClose={toggleExit} />}
    </div>
  );
}
