import classNames from "classnames";
import { FC } from "react";
import { createUseStyles } from "react-jss";

interface IProps {
  iconUrl: string;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: 8,
    borderRadius: 10,
  },
  text: {
    fontSize: 12,
  },
});

const IconTag: FC<IProps> = props => {
  const classes = useStyles();
  const { children, iconUrl, className } = props;
  return (
    <div className={classNames(classes.container, className)}>
      <img className={classes.icon} src={iconUrl} width={20} alt="protocol icon" />
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default IconTag;
